import axios from 'axios'
import config from '../config.json'
import { CONTACT_ID_VARIABLES } from '../constants'

const urls = {
  development: 'https://dev-api.lawstud.io',
  staging: 'https://staging-api.lawstud.io',
  production: 'https://api.lawstud.io'
}

const baseUrl = () => {
  if(config.useLocalLawstudio) {
    return 'http://localhost:8080'
  }
  return urls[config.environment]
}


const generate_document = async (templateObject, data, extension = 'pdf', images = {}, opts = {}) => {
  let enumMap = {}
  if(templateObject.sections) {
    enumMap = createEnumMap(templateObject.sections)
  }
  opts.enumMap = enumMap
  opts.templateId = templateObject.id
  return await generate_document_with_template_url(templateObject.templateUrl, data, extension, images, opts)
}

const generate_document_with_template_url = async (templateUrl, data, extension = 'pdf', images = {}, opts = {}) => {
  let processedData = {}
  for(let key in data) {
    if(Array.isArray(data[key]) && data[key].length > 0 && typeof data[key][0] === 'object') {
      processedData[key] = []
      for(let i in data[key]) {
        let procesesedRepeatable = {}
        for(let subKey in data[key][i]) {
          if(CONTACT_ID_VARIABLES.includes(subKey)) {
            continue
          }
          procesesedRepeatable[subKey] = processValue(data[key][i][subKey])
        }
        processedData[key].push(procesesedRepeatable)
      }
      continue
    }
    if(CONTACT_ID_VARIABLES.includes(key)) {
      continue
    }
    processedData[key] = processValue(data[key])
  }
  let enumMap = {}
  const url = `${baseUrl()}/create_document_with_url`
  const options = {
    convertTo: extension,
    enumMap,
    ...opts
  }
  if(images) {
    options.images = images
  }
  if(!options.font_config) {
    options.font_config = {}
  }
  try {
    let response = await axios.post(url, {
      template_url: templateUrl,
      data: processedData,
      extension,
      options
    }, {
      timeout: 120000
    })
    if(response.data.error) {
      console.log("lawstudio api error")
      console.log(response.data)
    }
    return response.data
  } catch(err) {
    console.log("caught lawstudio api error")
    console.log(err.response?.data || err)
    return { error: err.response?.data || err }
  }
}

const createEnumMap = (sections) => {
  let enumMap = {}
  for(let s in sections) {
    if(sections[s].variable) {
      let { key, map } = extractEnumMapFromSection(sections[s])
      if(key && map) {
        enumMap[key] = map
      }
    } else if(sections[s].variables) {
      for(let v in sections[s].variables) {
        let { key, map } = extractEnumMapFromSection(sections[s].variables[v])
        if(key && map) {
          enumMap[key] = map
        }
      }
    }
  }
  return enumMap
}

const extractEnumMapFromSection = (section) => {
  let key
  let map
  if(section.variable && section.options) {
    key = section.variable
    map = {}
    for(let o in section.options) {
      map[section.options[o].value] = section.options[o].value
    }
  }
  return { key, map}
}

const processValue = (value) => {
  // if(Array.isArray(value)) {
  //   if(value.length > 0 && typeof value[0] !== 'object') {
  //     return value.join('\n');
  //   } else {
  //     return value
  //   }
  // } else {
    // if(typeof value === 'string') {
    //   return value.replace(/;/g, '\\n')
    // } else {
    //   return value
    // }
  // }
  return value
}

const convert_attachment = async (attachmentUrl) => {
  const url = `${baseUrl()}/convert_attachment`
  let response = await axios.post(url, {
    attachment_url: attachmentUrl
  })
  if(response.data.error) {
    console.log("lawstudio api error")
    console.log(response.data)
  }
  return response.data
}

/**
 * 
 * @param {Object} attachment 
 * @param {String} attachment.url
 * @param {String} attachment.base64
 */
const compress_attachment = async ({ url, base64 }) => {
  try {
    const endpointUrl = `${baseUrl()}/v2/compress_attachment`
    const attachment = {}
    if(url) {
      attachment.url = url
    } if(base64) {
      attachment.base64 = base64
    }
    let response = await axios.post(endpointUrl, {
      ...attachment
    })
    if(response.data.error) {
      console.log("lawstudio api error")
      console.log(response.data)
    }
    return response.data  
  } catch(err) {
    console.log("caught lawstudio api error")
    console.log(err.response?.data || err)
    return { error: err.response?.data || err }
  }
}

export {
  generate_document,
  generate_document_with_template_url,
  convert_attachment,
  compress_attachment
}