import React, { useEffect } from 'react';

import { Modal, Input } from '../ui_new';
import useForm from '../../hooks/useForm';
import validate from '../../helpers/validate';

const CreateEditSectionModal = ({ onClose, mode = 'create', onCreate, onEdit, currentSection, loading, onSetCurrentSection }) => {
  const initialData = {
    name: {
      required: true,
      value: ''
    }
  };
  const { formData, errors, setErrors, changeHandler, setFormData } = useForm(initialData, validate);

  useEffect(() => {
    return () => {
      onSetCurrentSection && onSetCurrentSection(null);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(mode === 'edit') {
      setFormData(prev => ({
        ...prev,
        name: {
          ...prev.name,
          value: currentSection.name
        }
      }));
    }
    // eslint-disable-next-line
  }, [currentSection]);

  const submitHandler = async (e) => {
    e.preventDefault();
    
    const newErrors = validate(formData, true);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      if(mode === 'create') {
        const data = {
          name: formData.name.value.trim(),
          templates: [],
        }
        await onCreate(data);
      }else {
        const data = {
          name: formData.name.value.trim(),
        }
        onEdit(data, currentSection.id);
      }
    }
  }

  return (
    <Modal onClose={onClose} className="modal-v2--medium-3">
      <div className="folder-modal-v2">
        <div class="folder-modal-v2__heading">
          <h2>{mode === 'create' ? "Créer une catégorie" : "Modifier le catégorie"}</h2>
          <div class="flex gap-2">
            <button className='btn btn--medium btn--transparent' onClick={onClose} >
              Annuler
            </button>
            <button className="button height-40" disabled={loading}  onClick={submitHandler} >
              {loading ? mode === 'create' ? "Création..." : "Mise à jour..." : "Sauvegarder"}
            </button>
          </div>
        </div>
        <div class="folder-modal-v2__content">
          <form className="form">
            <Input
              name="name"
              value={formData.name.value}
              onChange={changeHandler}
              error={errors.name}
              placeholder="Indiquez le nom de la catégorie"
            />
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default CreateEditSectionModal;