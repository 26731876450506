import { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ReactSortable } from 'react-sortablejs'

import { Modal, Input, Switch, Alert, CustomTooltip, Checkbox, FileUploadDnD, Select, Option } from '../ui_new'
import { SignatureMailModalConfirm, SignaturesNotAvailableVialink, SingleDocumentSignContacts } from '.'
import { UserContext, NotificationContext, LoaderContext, SingleDocumentContext } from '../../context'
import { useDocumentSignActions, useSingleDocumentActions } from '../../hooks'
import { sortArrayOfObjects } from '../../utils'
import { fetch_customer_by_id, fetch_customers } from '../../services/firestore'
import { convert_attachment, generate_document } from '../../services/lawstudioApi';
import config from '../../config.json'
import { get_file_data } from '../../services/storage'
import { UserPlus, Chat, CalendarAlt, InfoCircle, ChevronLeft, Search, Drag, Delete, CheckCircle } from '../../assets/icons'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { Document, Page, pdfjs } from 'react-pdf'
import useVialinkActions from '../../hooks/useVialinkActions'
import Button from '../UI/Button'
import { ClickAwayListener } from '@material-ui/core'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { getAttachmentPreview } from '../../services/functions'
import { ArrowBack, DeleteOutline, DragIndicator } from '@mui/icons-material';
import { Add } from '@mui/icons-material'
import { environment } from '../../config.json'
import { AttachmentsContext } from '../../context/attachments/attachmentsState'
import { Warning } from "@material-ui/icons"
import { ATTACHMENT_RULES_FOR_TEMPLATE, isSignatureRulesConditionMet, SIGNATORY_CATEGORIES, SIGNATORY_RULES_FOR_TEMPLATE, SIGNATURE_MODE_FOR_TEMPLATE, VIALINK_SIGNATURE_DOCUMENT_MODE } from '../../utils/vialink'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MAX_ATTACHMENT_SIZE = 52428800

const MAX_ATTACHMENTS_COUNT = 50

const SingleDocumentSignVialink = ({ onClose, documentName, docId, docSignatureRecipients, singleDoc, docContacts, template, signatureObject, renderOptions }) => {
  // console.log("singleDoc", singleDoc)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const [requestSent, setRequestSent] = useState(false)
  const [responseStatus, setResponseStatus] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [signatureErrors, setSignatureErrors] = useState([])
  const [requestFailed, setRequestFailed] = useState(false)
  const [showSendRequestAlert, setShowSendRequestAlert] = useState(false)
  const [currentDocLoading, setCurrentDocLoading] = useState(false)
  const [currentDocLoaded, setCurrentDocLoaded] = useState(false)
  const [attachmentPreviewsLoaded, setAttachmentPreviewsLoaded] = useState(false)
  const [verifiedCustomers, setVerifiedCustomers] = useState([])
  const [loadingVerifiedCustomers, setLoadingVerifiedCustomers] = useState(false)
  const [validationErrorMessage, setValidationErrorMessage] = useState('')
  const [attachmentValidationErrorMessage, setAttachmentValidationErrorMessage] = useState('')
  const history = useHistory()

  const { submitForm, cancelSignature, submitSignatureUpdate } = useVialinkActions(template, { ...singleDoc })
  const { extractCustomersFromDataWithCustomMatching, getPreviewData } = useSingleDocumentActions()
  const { canUseSignatures, siteConfigsLoaded, agency, user, partner, admins, isNotSQHorCAIpartner, authenticationToken } = useContext(UserContext)
  const { setNotification } = useContext(NotificationContext)
  const { filteredDefaultAttachments, filteredDocumentAttachments } = useContext(SingleDocumentContext)

  // Disabled per request - MM-1129 Vialink: SQH partner
  // For signature contacts : we can remove the specific SQH ‘certified contacts’ fields and use the same fields as for the generic for the signataires
  const isSQHorCAI = false // !isNotSQHorCAIpartner

  const emptyTableRowData = { id: "1", category: '', firstName: '', lastName: '', email: '', phone: '' };

  const SIGNATORY_RULES = useMemo(() => {
    const rules = SIGNATORY_RULES_FOR_TEMPLATE[environment]?.[template?.id]
    if(!rules) return
    if(Array.isArray(rules)) {
      const ruleWithCondition = rules.find(rule => 
        isSignatureRulesConditionMet(rule.condition, singleDoc.values)
      )
      if(ruleWithCondition) {
        return ruleWithCondition.rule
      }
      const defaultRule = rules.find(rule => rule.default)
      if(defaultRule) {
        return defaultRule.rule
      }
      return
    } else {
      return rules
    }
  }, [template, singleDoc])

  const parsedDocContacts = useMemo(() => {
    if (SIGNATORY_RULES?.signatory_categories?.variablesMapping) {
      let contacts = extractCustomersFromDataWithCustomMatching(singleDoc.values, SIGNATORY_RULES?.signatory_categories?.variablesMapping)
      if (contacts.length === 0) {
        return [{ id: "1", category: '', firstName: '', lastName: '', email: '', phone: '' }]
      }
      if (SIGNATORY_RULES?.signatory_categories?.counts) {
        for (const typeKey in SIGNATORY_RULES?.signatory_categories?.counts) {
          if (typeKey === 'total') continue
          const rule = SIGNATORY_RULES?.signatory_categories?.counts[typeKey]
          const contactsOfType = contacts.filter(contact => contact.category === typeKey)
          if (rule.max) {
            contactsOfType.splice(rule.max)
          }
          contacts = contacts.filter(contact => contact.category !== typeKey).concat(contactsOfType)
        }
      }
      if (SIGNATORY_RULES?.signatory_categories?.counts?.total?.max) {
        contacts.splice(SIGNATORY_RULES?.signatory_categories?.counts?.total?.max)
      }
      return contacts.map((contact, index) => {
        let phone = (contact.phone || contact.telephone || '').trim()
        if (phone && phone[0] !== '+') {
          phone = `+33${phone}`
        }
        return {
          ...contact,
          firstName: contact.firstname,
          lastName: contact.lastname,
          phone: phone,
          email: contact.email || contact.email_address || '',
          id: String(index + 1)
        }
      })
    }
    if (!docContacts) return [{ id: "1", category: '', firstName: '', lastName: '', email: '', phone: '' }]
    const contacts = docContacts.map((contact, index) => {
      let phone = (contact.phone || contact.telephone || '').trim()
      if (phone && phone[0] !== '+') {
        phone = `+33${phone}`
      }
      return {
        ...contact,
        firstName: contact.firstname,
        lastName: contact.lastname,
        phone: phone,
        email: contact.email || contact.email_address || '',
        id: String(index + 1)
      }
    })
    if (contacts.length === 0) {
      return [{ id: "1", category: '', firstName: '', lastName: '', email: '', phone: '' }]
    }
    return contacts
  }, [docContacts, singleDoc, extractCustomersFromDataWithCustomMatching, SIGNATORY_RULES])


  const [activeTab, setActiveTab] = useState('SIGNATORIES'); //'SIGNATORIES' 'DOCUMENTS'
  const [showSection, setShowSection] = useState({ first: true, second: true, third: true, forth: true, });
  const [tableList, setTableList] = useState({ items: isSQHorCAI ? [] : parsedDocContacts });
  const emptyPeopleRowData = {
    firstName: '', lastName: '', email: '', valid: {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
    }
  };

  const signatureAttachments = useMemo(() => {
    return [
      ...(filteredDocumentAttachments || []).filter(att => att.type === 'application/pdf'),
      ...filteredDefaultAttachments,
    ]
  }, [filteredDocumentAttachments, filteredDefaultAttachments])

  const [peopleCopyReceiverList, setPeopleCopyReceiverList] = useState([emptyPeopleRowData]);
  const [signaturesOrder, setSignaturesOrder] = useState('unordered')
  const [pdfFiles, setPdfFiles] = useState([
    { name: singleDoc.name, type: 'application/pdf', file_type: 'document', attachment_mode: ATTACHMENT_RULES_FOR_TEMPLATE[environment]?.[template?.id]?.mainDocument || VIALINK_SIGNATURE_DOCUMENT_MODE.TO_BE_SIGNED },
    ...signatureAttachments.map((attachment) => ({ ...attachment, selected: false, file_type: 'attachment', attachment_mode: ATTACHMENT_RULES_FOR_TEMPLATE[environment]?.[template?.id]?.attachments || VIALINK_SIGNATURE_DOCUMENT_MODE.ATTACHED }))
  ]);
  const [maxAge, setMaxAge] = useState(10);
  const [reminder, setReminder] = useState('daily');
  const [emailData, setEmailData] = useState({ subject: '', message: '' })
  const [formValidation, setFormValidation] = useState({ showValidations: false })
  const [displayError, setDisplayError] = useState(false)
  const fileUploadRef = useRef();
  const errorMessage = useRef();
  const verifiedCustomersTimeout = useRef()


  const signatureMode = useMemo(() => {
    return SIGNATURE_MODE_FOR_TEMPLATE[environment]?.[template?.id] || template?.signature_mode || 'none'
  }, [template])

  const validSignatureModes = ['automatic', 'manual']
  const signaturesAvailable = Boolean(signatureObject) || (canUseSignatures && (!template || validSignatureModes.includes(signatureMode)))

  const SIGNATORY_CATEGORY_OPTIONS = useMemo(() => {
    if (SIGNATORY_RULES?.signatory_categories?.options) {
      return SIGNATORY_RULES?.signatory_categories?.options.map(category => SIGNATORY_CATEGORIES.find(cat => cat.value === category)).filter(Boolean)
    }
    return SIGNATORY_CATEGORIES
  }, [SIGNATORY_RULES])

  useEffect(() => {

    const fetchDocumentData = async () => {

      if (!template || !singleDoc || !agency || !user) return

      setCurrentDocLoading(true)
      const data = await getPreviewData(
        { ...singleDoc, name: documentName },
        template,
        partner === 'jurisur' &&
        config.environment === 'development',
        renderOptions
      )
      const documentBase64 = data?.split("base64,")[1]
      if (!documentBase64) {
        return;
      }
      const size = Buffer.from(documentBase64, 'base64').length
      setPdfFiles(files => {
        const newPdfFiles = [...files]
        newPdfFiles[0] = {
          ...newPdfFiles[0],
          data: documentBase64,
          size: size
        }
        return newPdfFiles
      })
      setCurrentDocLoaded(true)
      setCurrentDocLoading(false)
    }
    if (currentDocLoaded || currentDocLoading) return
    fetchDocumentData()
  }, [template, singleDoc, agency, user, currentDocLoading, currentDocLoaded, partner, renderOptions, documentName, getPreviewData])

  useEffect(() => {
    if (attachmentPreviewsLoaded) return
    const attachmentPreviewPromises = signatureAttachments.map(async (attachment) => {
      const url = attachment.url
      // if (attachment.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      //   try {
      //     const convertedData = await convert_attachment(attachment.url)

      //     const size = Buffer.from(convertedData.data, 'base64').length
      //     return {
      //       ...attachment,
      //       size: size,
      //       data: convertedData.data,
      //     }
      //   } catch (e) {
      //     return {
      //       ...attachment,
      //       size: NaN,
      //       data: '',
      //       error: "Une erreur s'est produite lors de la conversion de cette pièce jointe au format PDF"
      //     }
      //   }
      // } else 
      if (attachment.type !== 'application/pdf') {
        return {
          ...attachment,
          size: NaN,
          data: '',
          error: `La pièce jointe a un format invalide (${attachment.format || attachment.type})`
        }
      }
      if (url) {
        try {
          const response = await getAttachmentPreview({ attachment_url: url })
          return {
            ...attachment,
            size: response.size,
            // data: response.preview
          }
        } catch (e) {
          return {
            ...attachment,
            size: NaN,
            data: ''
          }
        }
      } else {
        return {
          ...attachment
        }
      }
    })

    Promise.all(attachmentPreviewPromises).then((attachments) => {
      setPdfFiles(files => {
        const newPdfFiles = [...files]
        attachments.forEach((attachment, index) => {
          newPdfFiles[index + 1] = {
            ...newPdfFiles[index + 1],
            size: attachment.size,
            data: attachment.data,
            error: attachment.error
          }
        })
        return newPdfFiles
      })
      setAttachmentPreviewsLoaded(true)
    })
  }, [singleDoc, attachmentPreviewsLoaded])

  useEffect(() => {
    if (!signatureObject) return
    setTableList({
      items: signatureObject.recipients.map((recipient) => ({
        firstName: recipient.firstName,
        lastName: recipient.lastName,
        email: recipient.email,
        phone: recipient.phone,
        category: recipient.category || '',
        status: recipient.status,
        participantId: recipient.participantId,
        contactId: recipient.contactId,
        valid: {
          firstName: true,
          lastName: true,
          email: true,
          phone: true,
          category: true
        }
      }))
    })
    setSignaturesOrder(signatureObject.order)
    setEmailData({ subject: signatureObject.email_data?.subject || '', message: signatureObject.email_data?.message || '' })
    setPeopleCopyReceiverList(signatureObject.completion_recipients.map((recipient) => ({
      firstName: recipient.firstName,
      lastName: recipient.lastName,
      email: recipient.email,
      contactId: recipient.contactId,
    })))
  }, [signatureObject])

  const onDragEnd = (e) => {
    if (!e.destination) {
      return;
    }
    const { items } = tableList;
    const sorted = reorder(items, e.source.index, e.destination.index);
    setTableList({ items: sorted });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onFileInputChange = (e) => {
    const inputFiles = e.target.files;
    if (inputFiles.length === 0) return;
    const newFiles = []
    for (let i = 0; i < inputFiles.length; i++) {
      const file = inputFiles[i];
      if (file.type !== 'application/pdf') {
        setNotification({ type: 'danger', msg: `Seules les pièces jointes au format PDF sont autorisées lors de l’envoi en signature. (${file.name})`, duration: 7000 })
        // e.target.value = ""
        continue;
      }
      if (file.size > MAX_ATTACHMENT_SIZE) {
        setNotification({ type: 'danger', msg: `La taille du fichier ne doit pas dépasser 50 Mo. (${file.name})` })
        // e.target.value = ""
        continue;
      }
      const fileName = file.name.split('.').slice(0, -1).join('.')
      newFiles.push({ file: file, name: fileName, type: file.type, size: file.size, selected: false, file_type: 'upload', attachment_mode: ATTACHMENT_RULES_FOR_TEMPLATE[environment]?.[template?.id]?.attachments || VIALINK_SIGNATURE_DOCUMENT_MODE.ATTACHED })
    }
    setPdfFiles((pdfFiles) => {
      e.target.value = "";
      return [...pdfFiles, ...newFiles]
    });
  };

  const isOverSize = (file) => {
    return file.size >= MAX_ATTACHMENT_SIZE
  };

  const isAttachmentSizeValid = useMemo(() => {
    return pdfFiles.every(file => file.size < MAX_ATTACHMENT_SIZE)
  }, [pdfFiles])

  const deletePdf = (index) => {
    setPdfFiles(files => {
      const newPdfFiles = [...files]
      newPdfFiles.splice(index, 1)
      return newPdfFiles
    });
  };

  const printSizeMegabytes = (sizeBytes, decimals = 2) => {
    return `${(sizeBytes / 1024 / 1024).toFixed(decimals)} MB`
  }

  const storeTableSelectData = (e, index) => {
    const newVal = tableList.items.map((item, i) => {
      if (i === index) {
        item['category'] = e;
        return item
      }
      return item
    })
    setTableList({ items: newVal })
  }

  const storeTableData = (e, index) => {
    const newVal = tableList.items.map((item, i) => {
      if (i === index) {
        item[e.target.name] = e.target.value
        return item
      }
      return item
    })
    setTableList({ items: newVal })
  }

  const storePeopleData = (e, index) => {
    const newVal = peopleCopyReceiverList.map((item, i) => {
      if (i === index) {
        item[e.target.name] = e.target.value
        return item
      }
      return item
    })
    setPeopleCopyReceiverList(newVal)
  }

  const addNewRow = (e) => {
    e.preventDefault();
    const newEmptyRow = { ...emptyTableRowData, id: String(tableList.items.length + 1) }
    setTableList({ items: [...tableList.items, newEmptyRow] });
  }

  const handleAddContact = (contact) => {
    let phone = contact.phone || contact.telephone || ''
    while (phone[0] === '0') {
      phone = phone.slice(1)
    }
    if (phone && phone[0] !== '+') {
      phone = `+33 ${phone}`
    }
    const email = contact.email || contact.email_address || ''
    const newEmptyRow = { ...emptyTableRowData, id: String(tableList.items.length + 1), firstName: contact.firstName, lastName: contact.lastName, email, phone }
    setTableList({ items: [...tableList.items, newEmptyRow] });
  }

  const addNewPeopleRow = (e) => {
    e.preventDefault();
    setPeopleCopyReceiverList([...peopleCopyReceiverList, emptyPeopleRowData]);
  }

  const deleteTableItem = (e, index) => {
    e.preventDefault();
    setTableList(prev => ({ items: prev.items.filter((elem, i) => i !== index) }));
  }

  const deletePeople = (e, index) => {
    e.preventDefault()
    setPeopleCopyReceiverList(prev => prev.filter((elem, i) => i !== index));
  }

  const checkValidation = useCallback((obj, useCategory = true) => {
    const emailCondition = obj.email && String(obj.email.toLowerCase())
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const phoneCondition = obj.phone && String(obj.phone).toLowerCase().replace(/[^0-9]/g, '').match(/\d{6,16}/)
    // const nameCondition = obj.name
    const firstnameCondition = obj.firstName
    const lastNameCondition = obj.lastName
    const valdicationObj = {
      firstName: !!firstnameCondition,
      lastName: !!lastNameCondition,
      email: !!emailCondition,
    }
    if (obj.phone !== undefined) {
      valdicationObj.phone = !!phoneCondition
    }
    if (signatureMode === "manual" && useCategory) {
      valdicationObj.category = !!obj.category
    }
    return valdicationObj
  }, [signatureMode]);

  useEffect(() => {
    // if(signatureObject) return // dont run this if signature object is loaded
    if (tableList.items && tableList.items !== formValidation.tableList) {

      setTableList(list => {
        let newItems = list.items.map((item, index) => {
          item.valid = checkValidation(item);
          return item;
        });
        setFormValidation(prev => ({ ...prev, tableList: newItems }))
        return { items: newItems }
      });

    }
  }, [tableList.items, formValidation.tableList, signatureObject, checkValidation])

  useEffect(() => {
    // if(signatureObject) return // dont run this if signature object is loaded
    if (peopleCopyReceiverList !== formValidation.peopleList) {

      setPeopleCopyReceiverList(list => {
        let newItems = list.map((item, index) => {
          if (item.firstName?.length > 0 || item.lastName?.length > 0 || item.email?.length > 0) {
            item.valid = checkValidation(item, false);
          }
          else {
            item.valid = {
              firstName: true,
              lastName: true,
              email: true,
            };
          }
          return item;
        });
        setFormValidation(prev => ({ ...prev, peopleList: newItems }))
        return newItems
      });
    }
  }, [peopleCopyReceiverList, formValidation.peopleList, signatureObject, checkValidation])



  const handleSubmitUpdate = async () => {
    if (Boolean(signatureObject) && !isFormValid()) {
      return
    }
    submitSignatureUpdate({
      signers: tableList.items.map(item => {
        const signerData = {
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          phone: item.phone?.replace(/\s/g, ''),
          contactId: item.contactId,
        }
        if (item.category) {
          signerData.category = item.category
        }
        return signerData
      }),
      completionRecipients: peopleCopyReceiverList.map(item => ({ firstName: item.firstName, lastName: item.lastName, email: item.email, contactId: item.contactId })),
      reminder,
      signature: signatureObject,
      setShowSendRequestAlert,
      setShowResponseLoader: setShowGlobalResponseLoader,
      setRequestSent, setSuccessMessage,
      setRequestFailed,
      setSignatureErrors,
      setResponseStatus
    })
  }

  const handleSubmitContacts = (e) => {
    e.preventDefault()
    setFormValidation(prev => ({ ...prev, showValidations: true }))
    if (Boolean(signatureObject) && isFormValid()) {
      handleSubmitUpdate()
      return
    }
    if (!Boolean(signatureObject) && isFormValid() && areContactsValid()) {
      setActiveTab('DOCUMENTS')
    } else {
      displayTabChangeError()
    }
  }

  const areContactsValid = useCallback(() => {
    if (signatureMode !== "manual") return true
    if (!SIGNATORY_RULES?.signatory_categories) return true
    const rules = SIGNATORY_RULES?.signatory_categories
    if (rules.options) {
      const validCategories = rules.options
      const areAllSignatoriesCategoriesValid = tableList.items.every(signatory => validCategories.includes(signatory.category))
      if (!areAllSignatoriesCategoriesValid) {
        setValidationErrorMessage('Veuillez sélectionner une catégorie pour chaque signataire')
        return false
      }
    }
    if (rules?.counts) {
      const countRulesPerCategory = rules?.counts
      for (let categoryKey in countRulesPerCategory) {
        if (categoryKey === 'total') continue
        const rule = countRulesPerCategory[categoryKey]
        const count = tableList.items.filter(signatory => signatory.category === categoryKey).length
        if (rule.min) {
          if (count < rule.min) {
            setValidationErrorMessage(rule.minError)
            return false
          }
        }
        if (rule.max) {
          if (count > rule.max) {
            setValidationErrorMessage(rule.maxError)
            return false
          }
        }
      }
    }
    setValidationErrorMessage('')
    return true
  }, [tableList, SIGNATORY_RULES, signatureMode])

  const isFormValid = () => {
    // setValidationErrorMessage('')
    function hasFalseValue(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === false) {
          return true;
        }
      }
      return false;
    }
    return !tableList.items.some(item => hasFalseValue(item.valid)) && !peopleCopyReceiverList.some(item => hasFalseValue(item.valid));
  }

  const areAttachmentsValid = () => {
    // check if at least one document is selected for signature
    return pdfFiles.some(file => file.attachment_mode === VIALINK_SIGNATURE_DOCUMENT_MODE.TO_BE_SIGNED)
  }

  // On submit
  const handleSubmit = (e) => {
    e.preventDefault()

    if (!areAttachmentsValid()) {
      setAttachmentValidationErrorMessage('Vous devez sélectionner au moins 1 document à signer.')
      return
    } else {
      setAttachmentValidationErrorMessage('')
    }

    submitForm({
      attachments: pdfFiles,
      signers: tableList.items.map(item => {
        const signerData = {
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          phone: item.phone?.replace(/\s/g, '')
        }
        if (item.category) {
          signerData.category = item.category
        }
        return signerData
      }),
      completionRecipients: peopleCopyReceiverList.map(item => ({ firstName: item.firstName, lastName: item.lastName, email: item.email })),
      emailData,
      maxAge,
      reminder,
      signaturesOrder,
      documentPages: 1, // currentDocNumOfPages,
      formBuilder: SIGNATORY_RULES?.formBuilder,
      setShowSendRequestAlert,
      setShowResponseLoader: setShowGlobalResponseLoader,
      setRequestSent, setSuccessMessage,
      setRequestFailed,
      setSignatureErrors,
    })
  }

  const displayTabChangeError = () => {
    setDisplayError(true)
    setTimeout(() => {
      document.querySelector('.signature-modal-v2').scroll({ top: 1000, left: 0, behavior: "smooth", })
    }, 0)
  }


  const handleCancelSignature = (e) => {
    e.preventDefault()
    if (window.confirm("Êtes-vous sûr de vouloir annuler cette cérémonie de signature?")) {
      cancelSignature({
        folderId: signatureObject.folder_id,
        signatureId: signatureObject.id,
        setShowResponseLoader: setShowGlobalResponseLoader,
        setSuccessMessage
      })
    }
  }

  const onCloseWithExtentedFeature = (e) => {
    if (signatureObject || requestSent || tableList.items.length === 0) {
      e.preventDefault()
      onClose()
      return
    }
    if (tableList.items[0].firstName || tableList.items[0].lastName || tableList.items[0].email || tableList.items[0].phone || peopleCopyReceiverList[0].firstName || peopleCopyReceiverList[0].lastName || peopleCopyReceiverList[0].email) {
      let text = "Souhaitez-vous abandonner cet envoi ? Les données saisies ne seront pas conservées.";
      if (window.confirm(text) === true) {
        e.preventDefault()
        onClose()
      } else {
        e.preventDefault()
      }
    } else {
      e.preventDefault()
      onClose()
    }
  }
  const goToDocumentsTab = () => {
    setDisplayError(false)
    setActiveTab('DOCUMENTS')
  }

  const handleVerifyDocument = async () => {
    onClose()
    history.push('/signatures')
  }

  const totalPrice = useMemo(() => {
    const basePrice = 0.9
    const additionalPrice = 0.46
    const additionalSigners = tableList.items.length - 6
    return (basePrice + (additionalSigners > 0 ? additionalSigners * additionalPrice : 0)).toFixed(2)
  }, [tableList.items])

  const fileNameWithExtension = (file) => {
    if (!file) return ''
    let fileExtension
    if (!file.type) {
      fileExtension = 'pdf'
    } else {
      fileExtension = file.type.split('/')[1].split('+')[0]
    }
    return `${file.name}.${fileExtension}`
  }

  const isSignatoryEditionLocked = () => {
    if (!signatureObject) return false
    // enable editing of signatories when signature is in progress & noone has signed yet
    const status = signatureObject.status
    if (status !== "IN_PROGRESS") return true
    const participantNotInProgress = signatureObject.recipients.some(recipient => recipient.status !== "IN_PROGRESS")
    if (participantNotInProgress) return true
    return false
  }

  const isContactLocked = (contact) => {
    if (!signatureObject) return false
    return Boolean(signatureObject) || !Boolean(contact.contactId)
  }

  const isSignatureLocked = () => {
    if (!signatureObject) return false
    return Boolean(signatureObject) && signatureObject.status !== "IN_PROGRESS"
  }

  const processedPhoneNumber = (number) => {
    if (!number) {
      return ''
    }
    return number.replace(/[^0-9+]/g, '')
  }

  // Search customers change handler
  const searchVerifiedCustomersChangeHandler = (value) => {
    if (verifiedCustomersTimeout.current) {
      clearTimeout(verifiedCustomersTimeout.current)
      verifiedCustomersTimeout.current = null
    }
    if (value === '') {
      setVerifiedCustomers([])
      setLoadingVerifiedCustomers(false)
    } else if (value.length > 2) {
      setLoadingVerifiedCustomers(true)
      verifiedCustomersTimeout.current = setTimeout(async () => {
        const res = await fetch_customers(authenticationToken, value)
        if (res && Array.isArray(res)) {
          const customersArr = []
          res.forEach(customer => {
            if (customer.email && customer.firstname && customer.lastname && customer.certification === 1) {
              customersArr.push({
                email: customer.email,
                firstName: customer.firstname,
                lastName: customer.lastname,
                phone: processedPhoneNumber(customer.mobile_phone || customer.phone || ''),
              })
            }
          })
          const sortedArr = sortArrayOfObjects(customersArr, 'lastName', 'asc')
          setVerifiedCustomers(sortedArr)
        }
        setLoadingVerifiedCustomers(false)
      }, 1000)
    }
  }

  const handleAttachmentTypeChange = (index, type) => {
    if (pdfFiles[index].file_type === "document" && ATTACHMENT_RULES_FOR_TEMPLATE[environment]?.[template?.id]?.mainDocument) return
    if (pdfFiles[index].attachment_mode === type) return
    if (pdfFiles[index].file_type !== "document" && ATTACHMENT_RULES_FOR_TEMPLATE[environment]?.[template?.id]?.attachments) {
      type = ATTACHMENT_RULES_FOR_TEMPLATE[environment]?.[template?.id]?.attachments
    }
    setPdfFiles(files => {
      const newPdfFiles = [...files]
      newPdfFiles[index] = {
        ...newPdfFiles[index],
        attachment_mode: type
      }
      return newPdfFiles
    })
  }

  const isSubmitEnabled = useMemo(() => {
    // Boolean(signatureObject) || !isAttachmentSizeValid
    return !Boolean(signatureObject) && isAttachmentSizeValid && pdfFiles.length > 0 && pdfFiles.length <= MAX_ATTACHMENTS_COUNT
  }, [isAttachmentSizeValid, pdfFiles, signatureObject])

  return (
    <>
      <Modal onClose={onCloseWithExtentedFeature} className="signature-modal-v2">

        {(!requestSent && signaturesAvailable && !signatureErrors.length > 0) ? (
          <div className="signature-modal-v2-inner">
            <div className="signature-modal-v2-inner__head">
              <div className='signature-heading'>
                {activeTab === 'DOCUMENTS' && <button className='button button--transparent return' onClick={() => setActiveTab('SIGNATORIES')}>
                  <ArrowBack fontSize={'small'} />
                  Retour
                </button>}
                <div className='signature-heading--title'>
                  <h1>{Boolean(signatureObject) ? "Modifier" : "Envoyer en signature électronique"}</h1>
                  <h2>{documentName}</h2>
                </div>
              </div>
              {activeTab === 'SIGNATORIES' &&
                <div className='buttons-row'>
                  <button className='btn btn--medium btn--transparent' onClick={onClose}>Annuler</button>
                  {/* <button className='btn btn--medium btn--transparent' onClick={signatureObject ? onClose : onCloseWithExtentedFeature}>{signatureObject ? 'Retour' : 'Annuler'}</button> */}
                  {signatureObject && !isSignatureLocked() && <button className='btn btn--large' onClick={handleCancelSignature}>Annuler l'envoi</button>}
                  <div
                    className='btn-separator'
                  >
                  </div>
                  {Boolean(signatureObject) && <button className='button action-btn bg-pink' onClick={handleSubmitContacts}>
                    <span>
                      <CheckCircle />Enregistrer
                    </span>
                  </button>}
                  {!Boolean(signatureObject) && <button className='btn btn--large' onClick={handleSubmitContacts}>Suivant</button>}

                </div>
              }
              {activeTab === 'DOCUMENTS' &&
                <div className='buttons-row'>
                  <button className='btn btn--medium btn--transparent' onClick={onClose}>Annuler</button>
                  <div
                    className='btn-separator'
                  >
                  </div>
                  <button className='button action-btn bg-pink' onClick={handleSubmit} disabled={!isSubmitEnabled}>
                    <span>
                      <span className='icon'> <CheckCircle /></span>
                      Envoyer
                    </span>
                  </button>
                </div>
              }
            </div>
            <div className="signature-modal-v2-inner__tabs">
              <div className="signature-modal-v2-inner__sidebar">
                <ul>
                  <li className={`${activeTab === 'SIGNATORIES' ? "active no-cursor" : ""}`} onClick={() => setActiveTab('SIGNATORIES')}>
                    <span>1</span> Sélection des signataires</li>
                  {!Boolean(signatureObject) && <li className={`${activeTab === 'DOCUMENTS' ? "active no-cursor" : ""}`} onClick={() => isFormValid() ? goToDocumentsTab() : displayTabChangeError()}>
                    <span>2</span> Sélection des documents</li>}
                </ul>
              </div>
              {activeTab === 'SIGNATORIES' && <div className="tab-content">
                <p className="top-text">Le prix de la cérémonie comprenant 6 signataires est de 0,90€ HT. A partir de 7 signataires, un supplément de 0,46€ HT par signataire supplémentaire sera appliqué.</p>

                <div className="form-parent">
                  <form>
                    <div className='heading-parent'>
                      <div className='heading' onClick={() => setShowSection(prev => ({ ...prev, first: !prev.first }))}>
                        <span className="text">Signataires</span>
                        <span className={`chevron ${showSection.first ? "open" : ""}`}><ChevronLeft /></span>
                      </div>
                    </div>
                    <SlideDown>
                      {showSection.first && <>
                        <div className='custom-radio'>
                          <div className="" onClick={isSignatureLocked() ? () => { } : () => setSignaturesOrder('unordered')}>
                            <input type="radio" id="first" name="radio-group" checked={signaturesOrder === 'unordered'} disabled={isSignatureLocked()} />
                            <label htmlFor="first"> Envoi en simultané</label>
                          </div>
                          <div className="" onClick={isSignatureLocked() ? () => { } : () => setSignaturesOrder('ordered')}>
                            <input type="radio" id="second" name="radio-group" checked={signaturesOrder === 'ordered'} disabled={isSignatureLocked()} />
                            <label htmlFor="second"> Envoi par ordre de signature</label>
                            <CustomTooltip content="Si vous choisissez l’envoi par ordre de signature, les signataires ne recevront pas en même temps la demande de signature, mais signeront les uns après les autres, selon l’ordre que vous aurez déterminé.">
                              <div className="info"> <InfoCircle /> </div>
                            </CustomTooltip>
                          </div>
                        </div>
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="Table">
                            {(provided) => (
                              <table {...provided.droppableProps} ref={provided.innerRef}>
                                <thead>
                                  <tr>
                                    {tableList.items.length > 1 && <th>

                                    </th>}
                                    {signatureMode === "manual" && <th>
                                      Catégorie
                                    </th>}
                                    <th>
                                      Prénom
                                    </th>
                                    <th>
                                      Nom
                                    </th>
                                    <th>
                                      <div>
                                        E-mail
                                        <CustomTooltip
                                          content="L’email est obligatoire car la demande de signature sera envoyée aux signataires à l’adresse email indiquée.">
                                          <div className="info"> <InfoCircle /> </div>
                                        </CustomTooltip>
                                      </div>
                                    </th>
                                    <th>
                                      <div>
                                        Numéro de <br /> téléphone mobile
                                        <CustomTooltip content="Le numéro de téléphone est obligatoire car chaque signataire recevra un code pour l'autoriser à accéder aux documents à signer.">
                                          <div className="info"> <InfoCircle /> </div>
                                        </CustomTooltip>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tableList?.items.map((item, index) => (
                                    <Draggable
                                      isDragDisabled={signaturesOrder === 'unordered'}
                                      key={`draggable_${item.id || index}`}
                                      draggableId={`${item.id || index}`}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <tr
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          {tableList.items.length > 1 && <td className={`p-0 ${tableList.items.length > 1 && signaturesOrder === 'ordered' ? '' : 'sm-d-none'}`}>
                                            <div className="actions-column">
                                              {signaturesOrder === 'ordered' && <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded attachment-reorder-icon">
                                                <DragIndicator fontSize='small' />
                                              </button>}
                                              {!signatureObject && <div className="action-delete--small">
                                                <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" onClick={(e) => deleteTableItem(e, index)}>
                                                  <DeleteOutline fontSize={'small'} />
                                                </button>
                                              </div>}
                                            </div>
                                          </td>}
                                          {signatureMode === "manual" && <td>

                                            <Select className={`${formValidation.showValidations && !item?.valid?.category ? 'invalid' : ''}`}
                                              name="category" selected={item.category || ''} onChange={(e) => storeTableSelectData(e, index)}
                                              disabled={isSignatoryEditionLocked()}>
                                              <Option value="" disabled>Sélectionner</Option>
                                              {SIGNATORY_CATEGORY_OPTIONS.map((category) => (
                                                <Option key={category.value} value={category.value}>{category.label}</Option>
                                              ))}
                                            </Select>
                                            {formValidation.showValidations && !item?.valid?.category && <span className='not-valid'>Non renseigné</span>}
                                          </td>}
                                          <td>
                                            <label className='field-label'>Prénom</label>
                                            <Input value={item.firstName} onChange={(e) => storeTableData(e, index)} name="firstName"
                                              displayPencilIcon={true} className={'mb-0'}
                                              error={formValidation.showValidations && !item?.valid?.firstName ? 'Non renseigné' : ''}
                                              disabled={isSignatoryEditionLocked() || isSQHorCAI} />

                                          </td>
                                          <td>
                                            <label className='field-label'>Nom</label>
                                            <Input value={item.lastName} onChange={(e) => storeTableData(e, index)} name="lastName"
                                              displayPencilIcon={true} className={'mb-0'}
                                              error={formValidation.showValidations && !item?.valid?.lastName ? 'Non renseigné' : ''}
                                              disabled={isSignatoryEditionLocked() || isSQHorCAI} />
                                          </td>
                                          <td>
                                            <label className='field-label'>E-mail</label>
                                            <Input value={item.email} onChange={(e) => storeTableData(e, index)} name="email"
                                              displayPencilIcon={true} className={'mb-0'}
                                              error={formValidation.showValidations && !item?.valid?.email ? 'Non valide' : ''}
                                              disabled={isSignatoryEditionLocked() || isSQHorCAI} />
                                          </td>
                                          <td>
                                            <label className='field-label'>Numéro de téléphone mobile</label>
                                            <div className={`phone-column ${formValidation.showValidations && !item?.valid?.phone ? 'invalid' : ''}`}>
                                              <PhoneInput
                                                inputProps={{ name: 'phone' }}
                                                country={"fr"}
                                                enableSearch={true}
                                                value={item.phone}
                                                onChange={(phone) => storeTableData({ target: { name: 'phone', value: phone } }, index)}
                                                disabled={isSignatoryEditionLocked() || isSQHorCAI}
                                                inputStyle={(isSignatoryEditionLocked() || isSQHorCAI) ? {
                                                  backgroundColor: '#f9f9f9',
                                                  color: '#9D8FBD',
                                                  borderColor: "#F0EBF9"
                                                } : {}}
                                                disableDropdown={true}
                                              />

                                            </div>
                                            {formValidation.showValidations && !item?.valid?.phone && <span className='not-valid'>Non valide</span>}
                                          </td>
                                          {!signatureObject && tableList.items.length > 1 && <td className="action-delete--large">
                                            <button className="button button--with-icon button--outline-primary btn--medium w-full mt-2 mb-2" onClick={(e) => deleteTableItem(e, index)}>
                                              <DeleteOutline fontSize={'small'} />
                                              Supprimer le signataire
                                            </button>
                                          </td>}
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </tbody>
                              </table>
                            )}
                          </Droppable>
                        </DragDropContext>
                        {!signatureObject && <ul className='search'>
                          {isSQHorCAI && <li>
                            <ContactSearch
                              title="Rechercher un contact certifié"
                              noDataText="Pas de contacts"
                              contacts={verifiedCustomers}
                              onAddContact={handleAddContact}
                              onSearchChange={searchVerifiedCustomersChangeHandler}
                              loading={loadingVerifiedCustomers}
                              searchDisabled={true}
                              minSearchLength={3}
                            />
                          </li>}
                          {!(SIGNATORY_RULES?.signatory_categories?.counts?.total?.max && SIGNATORY_RULES.signatory_categories?.counts.total.max <= tableList.items.length) &&
                            <li>
                              <ContactSearch
                                title="Sélectionner un signataire représentant l'agence"
                                noDataText="Pas de contacts"
                                contacts={(admins || []).filter(a => ["1", "2"].includes(`${a.status}`)).map(c => ({ ...c, firstName: c.firstname, lastName: c.lastname }))}
                                onAddContact={handleAddContact}
                              />
                            </li>
                          }
                        </ul>}

                        {!isSQHorCAI && !signatureObject &&
                          <button
                            className="button button--with-icon button--outline-primary height-40"
                            onClick={addNewRow}
                            disabled={SIGNATORY_RULES?.signatory_categories?.counts?.total?.max && SIGNATORY_RULES.signatory_categories?.counts.total.max <= tableList.items.length}>
                            <Add /> Ajouter un signataire
                          </button>
                        }
                        <br /><br />
                      </>}
                    </SlideDown>
                    {/* <div className='heading-parent'>
                  <div className='heading' onClick={() => setShowSection(prev => ({ ...prev, second: !prev.second }))}>
                    <div className='icon'>
                      <Chat />
                    </div>
                    <span className="text">Ecrire un message pour les destinataires</span>
                    <span className={`chevron ${showSection.second ? "open" : ""}`}><ChevronLeft /></span>
                  </div>
                </div>
                <SlideDown>
                  {showSection.second && <>
                    <div className="form-group">
                      <label>Objet</label>
                      <input className="custom-input" placeholder="[Immobilière d'exception] Documents à signer" value={emailData.subject} onChange={e => setEmailData({ ...emailData, subject: e.target.value })} disabled={Boolean(signatureObject)} />
                    </div>
                    <div className="form-group">
                      <label>Message
                        <CustomTooltip content="Ajouter un message dans l'e-mail envoyé au(x) signataire(s).">
                          <div className="info"> <InfoCircle /> </div>
                        </CustomTooltip>
                      </label>
                      <textarea className="custom-input" placeholder="Facultatif" value={emailData.message} onChange={e => setEmailData({ ...emailData, message: e.target.value })} disabled={Boolean(signatureObject)}></textarea>
                    </div>
                    <br /><br />
                  </>}
                </SlideDown> */}
                    <div className='heading' onClick={() => setShowSection(prev => ({ ...prev, third: !prev.third }))}>
                      <span className="text">Ajouter des personnes à notifier lorsque les documents seront signés</span>
                      <span className={`chevron ${showSection.third ? "open" : ""}`}><ChevronLeft /></span>
                    </div>
                    <SlideDown>
                      {showSection.third && <>
                        <table className="mb-4">
                          <thead>
                            <tr>
                              {peopleCopyReceiverList?.length > 1 && <th>
                              </th>}
                              <th>
                                Prénom du destinataire
                              </th>
                              <th>
                                Nom du destinataire
                              </th>
                              <th>
                                E-mail du destinataire
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {peopleCopyReceiverList?.map((item, index) => (
                              <tr key={`external_contact_${index}`}>
                                {peopleCopyReceiverList.length > 1 && <td className="p-0 action-delete--small">
                                  <div className="actions-column">
                                    {!signatureObject && <button className="delete-btn" onClick={(e) => deletePeople(e, index)}>
                                      <DeleteOutline fontSize={'small'} />
                                    </button>}
                                  </div>
                                </td>}
                                <td>
                                  <label className='field-label'>Prénom du destinaire</label>
                                  <Input value={item.firstName} onChange={(e) => storePeopleData(e, index)} name="firstName"
                                    className={'mb-0'}
                                    error={formValidation.showValidations && !item?.valid?.firstName ? 'Non renseigné' : ''}
                                    disabled={isSignatoryEditionLocked()} />
                                </td>
                                <td>
                                  <label className='field-label'>Nom du destinaire</label>
                                  <Input value={item.lastName} onChange={(e) => storePeopleData(e, index)} name="lastName"
                                    className={'mb-0'}
                                    error={formValidation.showValidations && !item?.valid?.lastName ? 'Non renseigné' : ''}
                                    disabled={isSignatoryEditionLocked()} />
                                </td>
                                <td>
                                  <label className='field-label'>E-mail du destinaire</label>
                                  <div className='email-parent'>
                                    <div>
                                      <Input value={item.email} onChange={(e) => storePeopleData(e, index)} name="email"
                                        className={'mb-0'}
                                        error={formValidation.showValidations && !item?.valid?.email ? 'Non valide' : ''}
                                        disabled={isSignatoryEditionLocked()} />
                                    </div>
                                  </div>
                                </td>
                                {!signatureObject && peopleCopyReceiverList.length > 1 && <td className="action-delete--large">
                                  <button className="button button--with-icon button--outline-primary btn--medium w-full mt-2 mb-2" onClick={(e) => deletePeople(e, index)}>
                                    <DeleteOutline fontSize={'small'} />
                                    Supprimer le contact
                                  </button>
                                </td>}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {!Boolean(signatureObject) && <ul className='search'>
                          <li>
                            <button className="button button--with-icon button--outline-primary height-40" onClick={addNewPeopleRow}>
                              <Add />  Ajouter un contact
                            </button>
                          </li>
                        </ul>}
                      </>}
                      <br />
                    </SlideDown>
                    <div className='heading' onClick={() => setShowSection(prev => ({ ...prev, forth: !prev.forth }))}>
                      <span className="text">Rappels et délai d'expiration</span>
                      <span className={`chevron ${showSection.forth ? "open" : ""}`}><ChevronLeft /></span>

                    </div>
                    <SlideDown>
                      {showSection.forth && <div className="flex flex-wrap w-full gap-4">
                        <div>
                          <label className='select-label'>Envoi d'un e-mail de rappel</label>
                          <br />
                          <Select className="w-64" disabled={isSignatureLocked()} selected={reminder} onChange={e => setReminder(e)}>
                            <Option value="daily">Quotidien</Option>
                            <Option value="semidaily" >Tous les 2 jours</Option>
                            <Option value="none">Ne pas envoyer de rappel</Option>
                          </Select>
                        </div>
                        <div className={'pl-2'}>
                          <label className='label'>Expiration</label>
                          <p className="form-text">
                            La demande de signature va expirer après
                            <input type="number" value={maxAge} onChange={e => setMaxAge(e.target.value)} className="custom-input number" disabled={Boolean(signatureObject)} />
                            jour(s).
                          </p>
                        </div>
                      </div>}
                    </SlideDown>
                    {(displayError || (!isFormValid() && formValidation.showValidations)) && <div className='error-message sent-error' ref={errorMessage}>

                      <Warning />
                      {validationErrorMessage || "Des informations sont manquantes ou mal renseignées."}
                    </div>}

                  </form>
                </div>
              </div>}
              {activeTab === 'DOCUMENTS' && <div className="tab-content overflow-hidden">
                <b className='title-text'>
                  <span className="text">Vérifiez les documents à envoyer</span>
                  <input type="file" multiple onChange={onFileInputChange} hidden ref={fileUploadRef} accept="application/pdf" />
                </b>
                {/** Documents table - columns: Name, Size, Checkboxes - to be signed / attachment, Delete button */}

                <div className='table-responsive overflow-x-auto'>

                  <table className="signature-attachments-table">
                    <thead>
                      <tr>
                        <th className='signature-attachments-table__column name'>Nom</th>
                        <th className='signature-attachments-table__column size'>Poids</th>
                        <th className='signature-attachments-table__column mode'>
                          <span className='flex'>
                            Presence signature
                            <CustomTooltip content="Précisez pour chaque document s'il est à signer ou à annexer">
                              <div className="info"> <InfoCircle /> </div>
                            </CustomTooltip>
                          </span>
                        </th>
                        <th className='signature-attachments-table__column actions'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {pdfFiles.map((pdfFile, index) => (
                        <tr key={`pdfFile_${index}`}>
                          <td className='signature-attachments-table__column name'>
                            <label className='field-label'>Nom</label>
                            {fileNameWithExtension(pdfFile)}
                          </td>
                          <td className='signature-attachments-table__column size'>
                            <label className='field-label'>Poids</label>
                            {pdfFile.size ? printSizeMegabytes(pdfFile.size) : "Chargement..."}
                          </td>
                          <td className='signature-attachments-table__column mode'>
                            <label className='field-label'>Presence signature</label>
                            <div className='flex flex-row w-min-content'>
                              <Checkbox
                                label={"à signer"}
                                checked={pdfFile.attachment_mode === VIALINK_SIGNATURE_DOCUMENT_MODE.TO_BE_SIGNED}
                                onChange={() => handleAttachmentTypeChange(index, VIALINK_SIGNATURE_DOCUMENT_MODE.TO_BE_SIGNED)}
                                disabled={
                                  Boolean(signatureObject) ||
                                  (pdfFile.file_type === "document" && ATTACHMENT_RULES_FOR_TEMPLATE[environment]?.[template?.id]?.mainDocument)
                                  ||
                                  (pdfFile.file_type !== "document" && ATTACHMENT_RULES_FOR_TEMPLATE[environment]?.[template?.id]?.attachments)
                                }
                              />
                              <Checkbox
                                className='ml-10'
                                label={"à annexer"}
                                checked={pdfFile.attachment_mode === VIALINK_SIGNATURE_DOCUMENT_MODE.ATTACHED}
                                onChange={() => handleAttachmentTypeChange(index, VIALINK_SIGNATURE_DOCUMENT_MODE.ATTACHED)}
                                disabled={
                                  Boolean(signatureObject) ||
                                  (pdfFile.file_type === "document" && ATTACHMENT_RULES_FOR_TEMPLATE[environment]?.[template?.id]?.mainDocument)
                                  ||
                                  (pdfFile.file_type !== "document" && ATTACHMENT_RULES_FOR_TEMPLATE[environment]?.[template?.id]?.attachments)
                                  ||
                                  pdfFile.file_type === "document"
                                }
                              />
                            </div>
                          </td>
                          <td className='signature-attachments-table__column actions action-delete--small'>
                            {(pdfFile.file_type !== "document" && !signatureObject) && <button onClick={() => deletePdf(index)} className="delete"><Delete /></button>}
                          </td>
                          {(pdfFile.file_type !== "document" && !signatureObject) && <td className='action-delete--large'>
                            <button onClick={() => deletePdf(index)} className="button button--with-icon button--outline-primary btn--medium w-full mt-2 mb-2">
                              <DeleteOutline fontSize={'small'} />
                              Supprimer
                            </button>
                          </td>}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {!signatureObject && (
                  <FileUploadDnD
                    label="Glisser-déposer un document"
                    buttonPosition="inside-right"
                    btnText="Sélectionner un document"
                    className="mt-20 mb-20"
                    height="sm"
                    onChange={(files) => onFileInputChange({ target: { files } })}
                    accept='application/pdf'
                    maxSize={50 * 1024 * 1024}
                    multiple={true}
                  />
                )}

                {pdfFiles.length > MAX_ATTACHMENTS_COUNT && <p className='text-error mb-20'>Vous avez atteint la limite de {MAX_ATTACHMENTS_COUNT} documents maximum à joindre à l'envoi. Vous pouvez néanmoins fusionner des annexes, dans la limite de 50 MB maximum par document.</p>}
                <p className="upload-text">
                  {pdfFiles.find(f => f.selected) && <span className={`${isOverSize(pdfFiles.find(f => f.selected)) ? 'over-size' : ''}`}> Taille du document sélectionné : {printSizeMegabytes(pdfFiles.find(f => f.selected).size)}<br /></span>}
                  <span className={!isAttachmentSizeValid ? 'over-size' : ''}>La taille maximale autorisée par document : 50 MB</span><br />
                  <span>Seules les pièces jointes au <b>format PDF</b> sont autorisées lors de l’envoi en signature.</span>
                </p>
                {attachmentValidationErrorMessage && <div className='error-message' ref={errorMessage}>
                  {attachmentValidationErrorMessage}
                </div>}
                <p className="information-box">
                  <InfoCircle />
                  <span>
                    Vous serez facturé <b>{totalPrice}</b>{`€ HT pour cet envoi (prix de la cérémonie à 0,90€ HT + 0,46€ HT par signataire supplémentaire au delà de 6 signataires).`}
                  </span>
                </p>


              </div>}
            </div>
          </div>) : !siteConfigsLoaded
          ? <div className="loader-wrapper"><div className="loader-v2"></div></div>
          : !signaturesAvailable && <SignaturesNotAvailableVialink />}

        {signatureErrors.length > 0 &&
          <div className="signatures-success-v2">
            <div className="signatures-success-v2__top">
            </div>
            <div className="message-v2 message-v2--error">{signatureErrors.join('')}</div>
            <div className="buttons-wrapper buttons-wrapper--gap-20">
              <button className="btn btn--medium btn--transparent btn--border-primary" type="button" onClick={() => setSignatureErrors([])}>Retour</button>
            </div>
          </div>
        }
        {requestSent &&
          <div className="folder-modal-v2">
            <div className='folder-modal-v2__heading'>
              <h2>{singleDoc.name}</h2>
              <div className="buttons-wrapper buttons-wrapper--gap-20">
                <button className="btn btn--medium btn--transparent btn--border-primary" type="button" onClick={onClose}>Fermer</button>
                <button className="btn btn--medium" type="button" onClick={handleVerifyDocument}>Vérifier le document</button>
              </div>
            </div>
            <div className='folder-modal-v2__content'>
              <div className='sent-successful'>
                <CheckCircle />{successMessage}
              </div>
            </div>
          </div>
        }

      </Modal>

      {responseStatus === 'success' &&
        <SignatureMailModalConfirm type="success" title="Envoi mis à jour" message="Les données de l'envoi ont été mises à jour"
          docId={signatureObject.doc_id}
          onClose={() => setResponseStatus('')} />
      }
      {responseStatus === 'error' &&
        <SignatureMailModalConfirm type="error" title="Envoi en erreur" message="Une erreur est survenue"
          docId={signatureObject.doc_id}
          onClose={() => setResponseStatus('')} />
      }
    </>
  )
}

const ContactSearch = ({ title, searchPlaceholder = "", contacts = [], onAddContact = () => { }, noDataText = "Pas de contacts", onSearchChange, loading, listVisible, searchDisabled, minSearchLength }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredContacts, setFilteredContacts] = useState([])
  const inputRef = useRef(null)

  useEffect(() => {
    if (!search || searchDisabled) {
      setFilteredContacts(contacts)
      return
    }
    const filtered = contacts.filter(a => {
      return (a.firstName || '').toLowerCase().includes(search.toLowerCase()) || (a.lastName || '').toLowerCase().includes(search.toLowerCase())
    })
    setFilteredContacts(filtered)
  }, [contacts, search, searchDisabled])

  const searchChangeHandler = (e) => {
    let searchString = (e.target.value || "").trim()

    setSearch(searchString)
    if (onSearchChange) {
      onSearchChange(searchString)
    }
    if (searchString) {
      setShowDropdown(true)
    } else {
      setShowDropdown(false)
    }
  }

  const handleContactSelected = (contact) => {
    setShowDropdown(false)
    if (!searchDisabled) {
      setSearch('')
    }
    onAddContact({
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email || contact.email_address,
      phone: contact.mobile || contact.mobile_phone || contact.phone || contact.phone_1 || contact.telephone || '',
    })
  }

  const handleFocus = (e) => {
    e.preventDefault()
    inputRef.current.focus()
    setShowDropdown(true)
  }

  const handleFocusCapture = (e) => {
    setShowDropdown(true)
    e.preventDefault()
    // inputRef.current.focus()
  }

  return (
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <div className={"search-row-wrapper"}>
        <div className='search-row-content'>
          <span>
            <Add />
            {title}
          </span>
          <div className='search-input'>
            <button onClick={handleFocus}><Search /></button>
            <input ref={inputRef} className="custom-input" placeholder={searchPlaceholder} onChange={searchChangeHandler} value={search} onFocusCapture={handleFocusCapture} onFocus={handleFocusCapture} />
          </div>
        </div>
        {loading ? (
          <div className="dropdown-el default-dropdown users-dropdown">
            <div className="loader-v2"></div>
          </div>
        ) : (showDropdown && search.length < minSearchLength) ? (
          <div className="dropdown-el default-dropdown users-dropdown">
            <p className="no-data">{`Tapez au moins ${minSearchLength} caractères`}</p>
          </div>
        ) : (showDropdown || listVisible) ?
          <div className="dropdown-el default-dropdown users-dropdown">
            {filteredContacts.length > 0 ? filteredContacts.map((a, i) => (
              <p key={`contact_filter_option_${i}`} onClick={() => handleContactSelected(a)}>{a.firstName} {a.lastName}</p>
            ))
              : <p className="no-data">{noDataText}</p>
            }
          </div>
          : <div></div>
        }
      </div>
    </ClickAwayListener>
  )
}

export default SingleDocumentSignVialink