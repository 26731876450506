
const SIGNATORY_TYPE = {
  "MANDANT": "principal",
  "MANDATAIRE": "representative",
  "CAUTIONNAIRE": "surety",
  "BAILLEUR": "lessor",
  "LOCATAIRE": "tenant",
  "ACQUEREUR": "acquirer",
  "VENDEUR": "seller",
  "PROPRIETAIRE": "owner",
}

const SIGNATORY_CATEGORIES = [{
  value: SIGNATORY_TYPE.VENDEUR,
  label: 'Vendeur'
}, {
  value: SIGNATORY_TYPE.PROPRIETAIRE,
  label: 'Propriétaire'
}, {
  value: SIGNATORY_TYPE.ACQUEREUR,
  label: 'Acquéreur'
}, {
  value: SIGNATORY_TYPE.BAILLEUR,
  label: 'Bailleur'
}, {
  value: SIGNATORY_TYPE.LOCATAIRE,
  label: 'Locataire'
}, {
  value: SIGNATORY_TYPE.MANDATAIRE,
  label: 'Mandataire'
}, {
  value: SIGNATORY_TYPE.CAUTIONNAIRE,
  label: 'Cautionnaire'
}, {
  value: SIGNATORY_TYPE.MANDANT,
  label: 'Mandant'
}]

const CUSTOM_MANDANT_MANDATAIRE_SIGNATORIES_RULE_DEV = {
  "name": "Mandant Mandataire DEV",
  "signatory_categories": {
    "options": [SIGNATORY_TYPE.MANDATAIRE, SIGNATORY_TYPE.MANDANT],
    "counts": {
      [SIGNATORY_TYPE.MANDATAIRE]: {
        "min": 1,
        "max": 1,
        "minError": "Aucun mandataire n'a été désigné",
        "maxError": "Seul un signataire peut être désigné comme mandataire"
      },
      [SIGNATORY_TYPE.MANDANT]: {
        "min": 1,
        "max": 5,
        "minError": "Aucun mandant n'a été désigné",
        "maxError": "Ce document n'est pas prévu pour être envoyé à plus de 5 mandants"
      }
    }
  },
  "formBuilder": {
    "url": "https://app.vialink.biz/xzibit/api/v1/form-builders/4c9e6938-03db-4699-90f0-051d74719895",
    "fieldsMapping": {
      [SIGNATORY_TYPE.MANDATAIRE]: "Mandataire",
      [SIGNATORY_TYPE.MANDANT]: "Vendeur_{index}"
    },
    "maskPage": {
      "add": false
    }
  }
}

// const Cautionnement solidaire unilatéral
const CAUTIONNEMENT_SOLIDAIRE_UNILATERAL_DEV = {
  "name": "Cautionnement solidaire unilatéral DEV",
  "signatory_categories": {
    "options": [SIGNATORY_TYPE.CAUTIONNAIRE],
    "counts": {
      [SIGNATORY_TYPE.CAUTIONNAIRE]: {
        "min": 1,
        "max": 1,
        "minError": "Aucun cautionnaire n'a été désigné",
        "maxError": "Seul un signataire peut être désigné comme cautionnaire"
      },
      "total": {
        "min": 1,
        "max": 1,
      }
    },
    "variablesMapping": {
      [SIGNATORY_TYPE.CAUTIONNAIRE]: {
        "ac3_prenom_caution": "firstname",
        "ac3_nom_caution": "lastname"
      }
    }
  },
  "formBuilder": {
    "url": "https://app.vialink.biz/xzibit/api/v1/form-builders/57928ed8-2c35-49c7-9707-6213accb99e1",
    "fieldsMapping": {
      [SIGNATORY_TYPE.CAUTIONNAIRE]: "Cautionnaire"
    },
    "maskPage": {
      "add": false
    }
  }
}
const CAUTIONNEMENT_SOLIDAIRE_UNILATERAL_PRODUCTION = {
  "name": "Cautionnement solidaire unilatéral PRODUCTION",
  "signatory_categories": {
    "options": [SIGNATORY_TYPE.CAUTIONNAIRE],
    "counts": {
      [SIGNATORY_TYPE.CAUTIONNAIRE]: {
        "min": 1,
        "max": 1,
        "minError": "Aucun cautionnaire n'a été désigné",
        "maxError": "Seul un signataire peut être désigné comme cautionnaire"
      },
      "total": {
        "min": 1,
        "max": 1,
      }
    },
    "variablesMapping": {
      [SIGNATORY_TYPE.CAUTIONNAIRE]: {
        "ac3_prenom_caution": "firstname",
        "ac3_nom_caution": "lastname"
      }
    }
  },
  "formBuilder": {
    "url": "https://app.vialink.fr/xzibit/api/v1/form-builders/592c39ed-b6ed-4183-b471-5085fe39fda3",
    "fieldsMapping": {
      [SIGNATORY_TYPE.CAUTIONNAIRE]: "Cautionnaire"
    },
    "maskPage": {
      "add": false
    }
  }
}
// const Cautionnement solidaire à durée déterminée
const CAUTIONNEMENT_SOLIDAIRE_A_DUREE_DETERMINEE_DEV = {
  "name": "Cautionnement solidaire à durée déterminée DEV",
  "signatory_categories": {
    "options": [SIGNATORY_TYPE.CAUTIONNAIRE, SIGNATORY_TYPE.BAILLEUR],
    "counts": {
      [SIGNATORY_TYPE.CAUTIONNAIRE]: {
        "min": 1,
        "max": 1,
        "minError": "Aucun cautionnaire n'a été désigné",
        "maxError": "Seul un signataire peut être désigné comme cautionnaire"
      },
      [SIGNATORY_TYPE.BAILLEUR]: {
        "min": 1,
        "max": 9,
        "minError": "Aucun bailleur n'a été désigné",
        "maxError": "Ce document n'est pas prévu pour être envoyé à plus de 9 bailleurs"
      },
      "total": {
        "min": 2,
        "max": 10,
      }
    },
    "variablesMapping": {
      [SIGNATORY_TYPE.CAUTIONNAIRE]: {
        "ac3_prenom_caution": "firstname",
        "ac3_nom_caution": "lastname"
      },
      [SIGNATORY_TYPE.BAILLEUR]: {
        "do_sellers_customers_firstname": "firstname",
        "do_sellers_customers_lastname": "lastname",
        "do_sellers_customers_email_address": "email",
        "do_sellers_customers_telephone": "phone",
        "do_co_seller_customers_firstname": "firstname",
        "do_co_seller_customers_lastname": "lastname",
        "do_co_seller_customers_email_address": "email",
        "do_co_seller_customers_telephone": "phone"
      }
    }
  },
  "formBuilder": {
    "url": "https://app.vialink.biz/xzibit/api/v1/form-builders/c587eed2-e2ee-4a60-be0b-fc202b9f0286",
    "fieldsMapping": {
      [SIGNATORY_TYPE.CAUTIONNAIRE]: "Cautionnaire",
      [SIGNATORY_TYPE.BAILLEUR]: "Bailleur {index}"
    },
    "maskPage": {
      "add": false
    }
  }
}
const CAUTIONNEMENT_SOLIDAIRE_A_DUREE_DETERMINEE_PRODUCTION = {
  "name": "Cautionnement solidaire à durée déterminée PRODUCTION",
  "signatory_categories": {
    "options": [SIGNATORY_TYPE.CAUTIONNAIRE, SIGNATORY_TYPE.BAILLEUR],
    "counts": {
      [SIGNATORY_TYPE.CAUTIONNAIRE]: {
        "min": 1,
        "max": 1,
        "minError": "Aucun cautionnaire n'a été désigné",
        "maxError": "Seul un signataire peut être désigné comme cautionnaire"
      },
      [SIGNATORY_TYPE.BAILLEUR]: {
        "min": 1,
        "max": 9,
        "minError": "Aucun bailleur n'a été désigné",
        "maxError": "Ce document n'est pas prévu pour être envoyé à plus de 9 bailleurs"
      },
      "total": {
        "min": 2,
        "max": 10,
      }
    },
    "variablesMapping": {
      [SIGNATORY_TYPE.CAUTIONNAIRE]: {
        "ac3_prenom_caution": "firstname",
        "ac3_nom_caution": "lastname"
      },
      [SIGNATORY_TYPE.BAILLEUR]: {
        "do_sellers_customers_firstname": "firstname",
        "do_sellers_customers_lastname": "lastname",
        "do_sellers_customers_email_address": "email",
        "do_sellers_customers_telephone": "phone",
        "do_co_seller_customers_firstname": "firstname",
        "do_co_seller_customers_lastname": "lastname",
        "do_co_seller_customers_email_address": "email",
        "do_co_seller_customers_telephone": "phone"
      }
    }
  },
  "formBuilder": {
    "url": "https://app.vialink.fr/xzibit/api/v1/form-builders/03a8427b-3b53-4eb6-9c72-e6a792c35980",
    "fieldsMapping": {
      [SIGNATORY_TYPE.CAUTIONNAIRE]: "Cautionnaire",
      [SIGNATORY_TYPE.BAILLEUR]: "Bailleur {index}"
    },
    "maskPage": {
      "add": false
    }
  }
}

const MANDAT_DE_VENTE_DEV = {
  "name": "Mandat de vente DEV",
  "signatory_categories": {
    "options": [SIGNATORY_TYPE.MANDATAIRE, SIGNATORY_TYPE.MANDANT],
    "counts": {
      [SIGNATORY_TYPE.MANDATAIRE]: {
        "min": 1,
        "max": 1,
        "minError": "Aucun mandataire n'a été désigné",
        "maxError": "Seul un signataire peut être désigné comme mandataire"
      },
      [SIGNATORY_TYPE.MANDANT]: {
        "min": 1,
        "max": 4,
        "minError": "Aucun mandant n'a été désigné",
        "maxError": "Ce document n'est pas prévu pour être envoyé à plus de 4 mandants"
      },
      "total": {
        "min": 2,
        "max": 5,
      }
    },
    "variablesMapping": {
      [SIGNATORY_TYPE.MANDATAIRE]: {
        "nego_bien_firstname": "firstname",
        "nego_bien_lastname": "lastname"
      },
      [SIGNATORY_TYPE.MANDANT]: {
        "customers_firstname": "firstname",
        "customers_lastname": "lastname",
        "customers_email_address": "email",
        "customers_telephone": "phone",
        "co_customers_firstname": "firstname",
        "co_customers_lastname": "lastname",
        "co_customers_email_address": "email",
        "co_customers_telephone": "phone",
      }
    }
  },
  "formBuilder": {
    "url": "https://app.vialink.biz/xzibit/api/v1/form-builders/17e7a02b-9535-482c-afcd-e580e04c31f0",
    "fieldsMapping": {
      [SIGNATORY_TYPE.MANDATAIRE]: "Mandataire",
      [SIGNATORY_TYPE.MANDANT]: "Mandant {index}"
    },
    "maskPage": {
      "add": false
    }
  }
}

const MANDAT_DE_VENTE_PRODUCTION = {
  "name": "Mandat de vente DEV",
  "signatory_categories": {
    "options": [SIGNATORY_TYPE.MANDATAIRE, SIGNATORY_TYPE.MANDANT],
    "counts": {
      [SIGNATORY_TYPE.MANDATAIRE]: {
        "min": 1,
        "max": 1,
        "minError": "Aucun mandataire n'a été désigné",
        "maxError": "Seul un signataire peut être désigné comme mandataire"
      },
      [SIGNATORY_TYPE.MANDANT]: {
        "min": 1,
        "max": 4,
        "minError": "Aucun mandant n'a été désigné",
        "maxError": "Ce document n'est pas prévu pour être envoyé à plus de 4 mandants"
      },
      "total": {
        "min": 2,
        "max": 5,
      }
    },
    "variablesMapping": {
      [SIGNATORY_TYPE.MANDATAIRE]: {
        "nego_bien_firstname": "firstname",
        "nego_bien_lastname": "lastname"
      },
      [SIGNATORY_TYPE.MANDANT]: {
        "customers_firstname": "firstname",
        "customers_lastname": "lastname",
        "customers_email_address": "email",
        "customers_telephone": "phone",
        "co_customers_firstname": "firstname",
        "co_customers_lastname": "lastname",
        "co_customers_email_address": "email",
        "co_customers_telephone": "phone",
      }
    }
  },
  "formBuilder": {
    "url": "TODO",
    "fieldsMapping": {
      [SIGNATORY_TYPE.MANDATAIRE]: "Mandataire",
      [SIGNATORY_TYPE.MANDANT]: "Mandant {index}"
    },
    "maskPage": {
      "add": false
    }
  }
}

const MANDAT_DE_RECHERCHE_DEV = {
  "name": "Mandat de Recherche DEV",
  "signatory_categories": {
    "options": [SIGNATORY_TYPE.MANDATAIRE, SIGNATORY_TYPE.MANDANT],
    "counts": {
      [SIGNATORY_TYPE.MANDATAIRE]: {
        "min": 1,
        "max": 1,
        "minError": "Aucun mandataire n'a été désigné",
        "maxError": "Seul un signataire peut être désigné comme mandataire"
      },
      [SIGNATORY_TYPE.MANDANT]: {
        "min": 1,
        "max": 4,
        "minError": "Aucun mandant n'a été désigné",
        "maxError": "Ce document n'est pas prévu pour être envoyé à plus de 4 mandants"
      },
      "total": {
        "min": 2,
        "max": 5,
      }
    },
    "variablesMapping": {
      [SIGNATORY_TYPE.MANDATAIRE]: {
        "nego_bien_firstname": "firstname",
        "nego_bien_lastname": "lastname"
      },
      [SIGNATORY_TYPE.MANDANT]: {
        "customers_firstname": "firstname",
        "customers_lastname": "lastname",
        "customers_email_address": "email",
        "customers_telephone": "phone",
        "co_customers_firstname": "firstname",
        "co_customers_lastname": "lastname",
        "co_customers_email_address": "email",
        "co_customers_telephone": "phone",
      }
    }
  },
  "formBuilder": {
    "url": "https://app.vialink.biz/xzibit/api/v1/form-builders/a87cc0a7-c3b8-436a-b2a9-3b553d68c045",
    "fieldsMapping": {
      [SIGNATORY_TYPE.MANDATAIRE]: "Mandataire",
      [SIGNATORY_TYPE.MANDANT]: "Mandant {index}"
    },
    "maskPage": {
      "add": false
    }
  }
}

const MANDAT_DE_RECHERCHE_PRODUCTION = {
  "name": "Mandat de Recherche PRODUCTION",
  "signatory_categories": {
    "options": [SIGNATORY_TYPE.MANDATAIRE, SIGNATORY_TYPE.MANDANT],
    "counts": {
      [SIGNATORY_TYPE.MANDATAIRE]: {
        "min": 1,
        "max": 1,
        "minError": "Aucun mandataire n'a été désigné",
        "maxError": "Seul un signataire peut être désigné comme mandataire"
      },
      [SIGNATORY_TYPE.MANDANT]: {
        "min": 1,
        "max": 4,
        "minError": "Aucun mandant n'a été désigné",
        "maxError": "Ce document n'est pas prévu pour être envoyé à plus de 4 mandants"
      },
      "total": {
        "min": 2,
        "max": 5,
      }
    },
    "variablesMapping": {
      [SIGNATORY_TYPE.MANDATAIRE]: {
        "nego_bien_firstname": "firstname",
        "nego_bien_lastname": "lastname"
      },
      [SIGNATORY_TYPE.MANDANT]: {
        "customers_firstname": "firstname",
        "customers_lastname": "lastname",
        "customers_email_address": "email",
        "customers_telephone": "phone",
        "co_customers_firstname": "firstname",
        "co_customers_lastname": "lastname",
        "co_customers_email_address": "email",
        "co_customers_telephone": "phone",
      }
    }
  },
  "formBuilder": {
    "url": "TODO",
    "fieldsMapping": {
      [SIGNATORY_TYPE.MANDATAIRE]: "Mandataire",
      [SIGNATORY_TYPE.MANDANT]: "Mandant {index}"
    },
    "maskPage": {
      "add": false
    }
  }
}

const LETTRE_INTENTION_ACHAT_CASE1_DEV = {
  "name": "Lettre d'intention d'achat - CASE 1 DEV",
  "signatory_categories": {
    "options": [SIGNATORY_TYPE.VENDEUR, SIGNATORY_TYPE.ACQUEREUR],
    "counts": {
      [SIGNATORY_TYPE.ACQUEREUR]: {
        "min": 1,
        "max": 2,
        "minError": "Aucun acquéreur n'a été désigné",
        "maxError": "Ce document n'est pas prévu pour être envoyé à plus de 2 acquéreurs"
      },
      [SIGNATORY_TYPE.VENDEUR]: {
        "min": 1,
        "max": 4,
        "minError": "Aucun vendeur n'a été désigné",
        "maxError": "Ce document n'est pas prévu pour être envoyé à plus de 4 vendeurs"
      },
      "total": {
        "min": 2,
        "max": 6,
      }
    },
    "variablesMapping": {
      [SIGNATORY_TYPE.VENDEUR]: {
        "do_sellers_customers_firstname": "firstname",
        "do_sellers_customers_lastname": "lastname",
        "do_sellers_customers_email_address": "email",
        "do_sellers_customers_telephone": "phone",
        "do_co_seller_customers_firstname": "firstname",
        "do_co_seller_customers_lastname": "lastname",
        "do_co_seller_customers_email_address": "email",
        "do_co_seller_customers_telephone": "phone",
      },
      [SIGNATORY_TYPE.ACQUEREUR]: {
        "do_customers_firstname": "firstname",
        "do_customers_lastname": "lastname",
        "do_customers_email_address": "email",
        "do_customers_telephone": "phone",
        "do_co_customers_firstname": "firstname",
        "do_co_customers_lastname": "lastname",
        "do_co_customers_email_address": "email",
        "do_co_customers_telephone": "phone",
      }
    }
  },
  "formBuilder": {
    "url": "https://app.vialink.biz/xzibit/api/v1/form-builders/c1dacda3-09cb-41c5-912d-128391e1fc39",
    "fieldsMapping": {
      [SIGNATORY_TYPE.VENDEUR]: "Vendeur {index}",
      [SIGNATORY_TYPE.ACQUEREUR]: "Acquéreur {index}"
    },
    "maskPage": {
      "add": false
    }
  }
}

const LETTRE_INTENTION_ACHAT_CASE2_DEV = {
  "name": "Lettre d'intention d'achat - CASE 2 DEV",
  "signatory_categories": {
    "options": [SIGNATORY_TYPE.VENDEUR, SIGNATORY_TYPE.ACQUEREUR],
    "counts": {
      [SIGNATORY_TYPE.ACQUEREUR]: {
        "min": 1,
        "max": 2,
        "minError": "Aucun acquéreur n'a été désigné",
        "maxError": "Ce document n'est pas prévu pour être envoyé à plus de 2 acquéreurs"
      },
      [SIGNATORY_TYPE.VENDEUR]: {
        "min": 1,
        "max": 4,
        "minError": "Aucun vendeur n'a été désigné",
        "maxError": "Ce document n'est pas prévu pour être envoyé à plus de 4 vendeurs"
      },
      "total": {
        "min": 2,
        "max": 6,
      }
    },
    "variablesMapping": {
      [SIGNATORY_TYPE.VENDEUR]: {
        "do_sellers_customers_firstname": "firstname",
        "do_sellers_customers_lastname": "lastname",
        "do_sellers_customers_email_address": "email",
        "do_sellers_customers_telephone": "phone",
        "do_co_seller_customers_firstname": "firstname",
        "do_co_seller_customers_lastname": "lastname",
        "do_co_seller_customers_email_address": "email",
        "do_co_seller_customers_telephone": "phone",
      },
      [SIGNATORY_TYPE.ACQUEREUR]: {
        "do_customers_firstname": "firstname",
        "do_customers_lastname": "lastname",
        "do_customers_email_address": "email",
        "do_customers_telephone": "phone",
        "do_co_customers_firstname": "firstname",
        "do_co_customers_lastname": "lastname",
        "do_co_customers_email_address": "email",
        "do_co_customers_telephone": "phone",
      }
    }
  },
  "formBuilder": {
    "url": "https://app.vialink.biz/xzibit/api/v1/form-builders/8c4876a5-7585-40f1-9673-b50d3c41b527",
    "fieldsMapping": {
      [SIGNATORY_TYPE.VENDEUR]: "Vendeur {index}",
      [SIGNATORY_TYPE.ACQUEREUR]: "Acquéreur {index}"
    },
    "maskPage": {
      "add": false
    }
  }
}

const CUSTOM_SIGNATURE_RULES_DEV = {
  "MANDANT_MANDATAIRE": CUSTOM_MANDANT_MANDATAIRE_SIGNATORIES_RULE_DEV,
  "CAUTIONNEMENT_SOLIDAIRE_UNILATERAL": CAUTIONNEMENT_SOLIDAIRE_UNILATERAL_DEV,
  "CAUTIONNEMENT_SOLIDAIRE_A_DUREE_DETERMINEE": CAUTIONNEMENT_SOLIDAIRE_A_DUREE_DETERMINEE_DEV,
  "MANDAT_DE_VENTE": MANDAT_DE_VENTE_DEV,
  "MANDAT_DE_RECHERCHE": MANDAT_DE_RECHERCHE_DEV,
  "LETTRE_INTENTION_ACHAT_CASE1": LETTRE_INTENTION_ACHAT_CASE1_DEV,
  "LETTRE_INTENTION_ACHAT_CASE2": LETTRE_INTENTION_ACHAT_CASE2_DEV,
}

const CUSTOM_SIGNATURE_RULES = {
  "CAUTIONNEMENT_SOLIDAIRE_UNILATERAL": CAUTIONNEMENT_SOLIDAIRE_UNILATERAL_PRODUCTION,
  "CAUTIONNEMENT_SOLIDAIRE_A_DUREE_DETERMINEE": CAUTIONNEMENT_SOLIDAIRE_A_DUREE_DETERMINEE_PRODUCTION,
  "MANDAT_DE_VENTE": MANDAT_DE_VENTE_PRODUCTION,
  "MANDAT_DE_RECHERCHE": MANDAT_DE_RECHERCHE_PRODUCTION
}

const SIGNATURE_MODE_FOR_TEMPLATE = {
  "development": {
    "R60kfjH51qyO5XIryVsA": "manual",
  },
  "staging": {
    "SWZWslvkqbQoh1dbQRYs": "manual",
    "9oZcK02GXYxoeU6IB0WP": "manual",
    "sa72j1oDfhtWgcQDClri": "manual",
    "pzIhC7RB0x9qgt1IuCvt": "manual",
    "WDegrtR9R9wZ1dwrlAJo": "manual",
    "vFvAG6HCN0wEt7cfaih0": "manual",
    "SfRWFSHHz6phOwme93ox": "manual",
    "NG7HAoIQ4kWP97S4z82q": "manual",
    "MmnTDqOa53nfvPiny6aS": "manual",
  },
  "production": {
    "lfUVYCuuOHlaDpC8nuBq": "manual",
    "5w6pTjWxaFnBHNgi83wM": "manual",
    "YEETnqdtdP08NEHGMqOq": "manual",
    "J75GpoEUQ3WHfiwwhpWR": "manual",
    "EiNQTISS8distfO2bEuh": "manual",
    "hk48e2VTcLnxTIwJxVJo": "manual",
    // TODO pending validation
    // "MgD5K1MGDAWuMh1uBKOe": "manual",
    // "VDOORLdX5BxyvICgyzf4": "manual",
  }
}

const SIGNATORY_RULES_FOR_TEMPLATE = {
  "development": {
    "R60kfjH51qyO5XIryVsA": CUSTOM_SIGNATURE_RULES_DEV.MANDANT_MANDATAIRE
  },
  "staging": {
    "SWZWslvkqbQoh1dbQRYs": CUSTOM_SIGNATURE_RULES_DEV.MANDANT_MANDATAIRE,
    "9oZcK02GXYxoeU6IB0WP": CUSTOM_SIGNATURE_RULES_DEV.MANDANT_MANDATAIRE,
    "sa72j1oDfhtWgcQDClri": CUSTOM_SIGNATURE_RULES_DEV.MANDANT_MANDATAIRE,
    "pzIhC7RB0x9qgt1IuCvt": CUSTOM_SIGNATURE_RULES_DEV.MANDANT_MANDATAIRE,
    "WDegrtR9R9wZ1dwrlAJo": CUSTOM_SIGNATURE_RULES_DEV.CAUTIONNEMENT_SOLIDAIRE_UNILATERAL,
    "vFvAG6HCN0wEt7cfaih0": CUSTOM_SIGNATURE_RULES_DEV.CAUTIONNEMENT_SOLIDAIRE_A_DUREE_DETERMINEE,
    "SfRWFSHHz6phOwme93ox": CUSTOM_SIGNATURE_RULES_DEV.MANDAT_DE_VENTE,
    "MmnTDqOa53nfvPiny6aS": CUSTOM_SIGNATURE_RULES_DEV.MANDAT_DE_RECHERCHE,
    "NG7HAoIQ4kWP97S4z82q": [{
      default: true,
      rule: CUSTOM_SIGNATURE_RULES_DEV.LETTRE_INTENTION_ACHAT_CASE1
    }, {
      condition: {
        variable: "sh_prestataire_demenagement",
        value: ["Bemove", "Papernest", "Autre"],
        relation: "in"
      },
      rule: CUSTOM_SIGNATURE_RULES_DEV.LETTRE_INTENTION_ACHAT_CASE2
    }],
  },
  "production": {
    "EiNQTISS8distfO2bEuh": CUSTOM_SIGNATURE_RULES.CAUTIONNEMENT_SOLIDAIRE_UNILATERAL,
    "hk48e2VTcLnxTIwJxVJo": CUSTOM_SIGNATURE_RULES.CAUTIONNEMENT_SOLIDAIRE_A_DUREE_DETERMINEE,
    // TODO pending validation
    // "MgD5K1MGDAWuMh1uBKOe": CUSTOM_SIGNATURE_RULES.MANDAT_DE_VENTE,
    // "VDOORLdX5BxyvICgyzf4": CUSTOM_SIGNATURE_RULES.MANDAT_DE_RECHERCHE,
  }
}

const isSignatureRulesConditionMet = (condition, documentValues) => {
  if(!condition) return false
  if(!documentValues) return false
  const { variable, value, relation } = condition
  if(relation === "in") {
    if(!Array.isArray(value)) {
      return false
    }
    return value.includes(documentValues[variable])
  } else if(relation === "equal") {
    return documentValues[variable] === value
  }
  return false
}

const VIALINK_SIGNATURE_DOCUMENT_MODE = {
  "TO_BE_SIGNED": "TO_BE_SIGNED",
  "ATTACHED": "ATTACHED",
}

const ATTACHMENT_RULES_MAIN_ONLY = {
  "mainDocument": VIALINK_SIGNATURE_DOCUMENT_MODE.TO_BE_SIGNED,
  "attachments": VIALINK_SIGNATURE_DOCUMENT_MODE.ATTACHED
}

const ATTACHMENT_RULES_FOR_TEMPLATE = {
  "development": {
    "R60kfjH51qyO5XIryVsA": ATTACHMENT_RULES_MAIN_ONLY
  },
  "staging": {
    "SWZWslvkqbQoh1dbQRYs": ATTACHMENT_RULES_MAIN_ONLY,
    "9oZcK02GXYxoeU6IB0WP": ATTACHMENT_RULES_MAIN_ONLY,
    "sa72j1oDfhtWgcQDClri": ATTACHMENT_RULES_MAIN_ONLY,
    "pzIhC7RB0x9qgt1IuCvt": ATTACHMENT_RULES_MAIN_ONLY,
    "WDegrtR9R9wZ1dwrlAJo": ATTACHMENT_RULES_MAIN_ONLY,
    "vFvAG6HCN0wEt7cfaih0": ATTACHMENT_RULES_MAIN_ONLY,
    "SfRWFSHHz6phOwme93ox": ATTACHMENT_RULES_MAIN_ONLY,
    "NG7HAoIQ4kWP97S4z82q": ATTACHMENT_RULES_MAIN_ONLY,
    "MmnTDqOa53nfvPiny6aS": ATTACHMENT_RULES_MAIN_ONLY
  },
  "production": {
    "EiNQTISS8distfO2bEuh": ATTACHMENT_RULES_MAIN_ONLY,
    "hk48e2VTcLnxTIwJxVJo": ATTACHMENT_RULES_MAIN_ONLY,
    "MgD5K1MGDAWuMh1uBKOe": ATTACHMENT_RULES_MAIN_ONLY,
    "VDOORLdX5BxyvICgyzf4": ATTACHMENT_RULES_MAIN_ONLY,
  }
}


export {
  SIGNATORY_CATEGORIES,
  SIGNATURE_MODE_FOR_TEMPLATE,
  SIGNATORY_RULES_FOR_TEMPLATE,
  ATTACHMENT_RULES_FOR_TEMPLATE,
  VIALINK_SIGNATURE_DOCUMENT_MODE,
  isSignatureRulesConditionMet
}