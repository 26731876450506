import { useCallback, useContext, useState } from 'react'

import { Checkbox, CustomTooltip, Select, Option, Modal, Alert } from '../ui_new'
import Button from '../UI/Button'
import { areSectionConditionsMet, FEATURE, formulaResult, getFileData, isFeatureEnabled, parseAreaValue, printAreaValue } from '../../utils'
import { CONTACT_ID_VARIABLES, CONTACT_TYPE_FOR_ID_VARIABLE, NUMBER_LINES, TEXT_LINES } from '../../constants'
import { SingleDocumentVariablesInner } from './'
import { useAttachmentActions, useVariablesActions } from '../../hooks'
import { VariableDateInput, VariableTextInput, TextInput, VariableCustomerInput, VariableTextarea, VariableEmptyLinesInput, VariableText } from './'
import AttachmentsVariableModal from './AttachmentsVariableModal'
import byteConverter from '../../helpers/byteConverter'
import { Upload, Draft, Eye } from '../../assets/icons'
import { DeleteOutline } from '@mui/icons-material'
import { DocumentsContext, LoaderContext, NotificationContext } from '../../context'

const SingleDocumentVariablesSectionContent = ({
  type,
  content,
  bulletListItems,
  variable,
  variables,
  data_type,
  question,
  options,
  tooltip,
  condition,
  conditions,
  values,
  onValuesChange,
  index,
  varIndex,
  // repeatableSectionId,
  // repeatableIndex,
  head,
  row,
  repeatableRowId,
  rows,
  onAgencyModalOpen = () => { },
  onNotariesModalOpen = () => { },
  linesAdded = false,
  documentCheckboxValues,
  onCheckboxValueChange,
  locked,
  rowsLocked,
  isContentEditable,
  onSectionContentChanged,
  onSetDocumentAttachments,
  repeatableIds,
  repetitionIndices,
  sectionIdx,
  documentAttachments,
  defaultAttachments,
  display_name,
  docId
}) => {
  const {
    isCoverPageSection,
    isHandleLocked,
    fieldKeyFromHandle,
    checkboxIdFromHandle,
    variableWithField,
    isRepeatableBlockHandle,
    value,
    emptyLinesLabelForValue,
    customersArrayForType,
    dataKeyArrayForType,
    customerKeyForType,
    duplicatableValuesArray
  } = useVariablesActions({ variable, variables, bulletListItems, repeatableIds, repetitionIndices, type, values, onValuesChange })

  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { updateDocument } = useContext(DocumentsContext)
  const { previewAttachment } = useAttachmentActions()

  const [attachmentSelectModal, setAttachmentSelectModal] = useState({
    isOpen: false,
    variable: null,
    repeatableIds: [],
    repetitionIndices: [],
    value: []
  })

  const [deleteAttachmentAlert, setDeleteAttachmentAlert] = useState({
    open: false,
    attachment: null
  })

  const decodeHtml = (html) => {
    let txt = document.createElement('textarea')
    txt.innerHTML = html
    let val = txt.value

    val = val.replace(/\u0002/g, '')
    val = val.replace(//g, '')
    return val
  }

  const onContentChanged = (originalContent, listIndex) => (e) => {
    if (!e.target) {
      return
    }

    let editedContent = decodeHtml(e.target.innerText)
    const brRegex = /<br>/g
    editedContent = editedContent.replace(brRegex, '\u0002')

    if (editedContent === "") {
      editedContent = "\u0002"
    }

    if (editedContent === originalContent) {
      return
    }

    onSectionContentChanged({
      sectionIndex: index,
      listIndex: listIndex,
      editedContent,
      originalContent
    })
  }

  // Render heading 1
  const renderHeading1 = () => {
    return (
      <div className="single-document-variable-head">
        <div className="single-document-variable-head__top">
          <h1>
            {renderContent()}
          </h1>
        </div>
      </div>
    )
  }

  // Render heading 2
  const renderHeading2 = () => {
    return (
      <div className="single-document-variable-head">
        <div className="single-document-variable-head__top">
          <h2>
            {renderContent()}
          </h2>
        </div>
      </div>
    )
  }

  // Render heading 3
  const renderHeading3 = () => {
    return (
      <div className="single-document-variable-head">
        <h3>
          {renderContent()}
        </h3>
      </div>
    )
  }

  // Render question
  const renderQuestion = (fieldVariable = null, handle = '', repSectionIds = repeatableIds, repIndices = repetitionIndices, itemIndex = -1) => {
    let q
    let o
    let v
    let dt
    let t
    let idx
    let lckd
    let dn
    if (fieldVariable) {
      t = fieldVariable.tooltip
      q = fieldVariable.tooltip
      o = fieldVariable.options || []
      v = fieldVariable.variable
      dt = fieldVariable.type
      idx = fieldVariable.idx
      dn = fieldVariable.display_name
      lckd = isHandleLocked(handle)
    } else {
      q = question
      o = options || []
      v = variable
      dt = data_type
      t = tooltip
      idx = sectionIdx || itemIndex
      dn = display_name
      lckd = isHandleLocked(handle)
    }
    if (dt === 'empty_lines') {
      // return renderEmptyLinesInput({ question: q, options: o, variable: v, data_type: dt, type: dt, varIndex: idx, tooltip: t}, handle)
      return <VariableEmptyLinesInput
        key={`key_${v}_${idx}`}
        fieldVariable={{ question: q, options: o, variable: v, data_type: dt, type: dt, varIndex: idx, tooltip: t }}
        handle={handle}
        repeatableIds={repSectionIds}
        repetitionIndices={repIndices}
        val={value(v, repSectionIds, repIndices)}
        emptyLinesLabelForValue={emptyLinesLabelForValue}
        onInputChange={onInputChange}
      />
    } else if (dt === 'select') {
      return <SingleDocumentVariablesInner
        key={`key_${v}_${idx}`}
        title={q}
        choices={o}
        choicesName={v}
        documentValues={values}
        onValueChange={val => onInputChange(v, val, repSectionIds, repIndices)}
        tooltip={t}
        variable={v}
        id={v}
        idx={`${idx}${repeatableIds?.length > 0 ? `-${repeatableIds.map((repId, repIdIndex) => `${repId}-${repetitionIndices?.[repIdIndex]}`).join('-')}` : ''}`}
        disabled={lckd}
        repeatableIds={repSectionIds}
        repetitionIndices={repIndices}
      />;
    } else if (dt === 'attachment') {
      return renderAttachmentInput({ question: q, options: o, variable: v, data_type: dt, type: dt, varIndex: idx, tooltip: t, display_name: dn }, handle, repSectionIds, repIndices)
    } else {

      const val = value(v, repeatableIds, repetitionIndices)

      return (
        <div className="tooltip-wrapper doc-variable-el" data-id={idx} key={`key_${index}`}>
          <CustomTooltip content={t}>
            <div className="variables-v2__head">
              <div className="variables-v2__head_text">
                <div className="fields-opened">
                  <p>{`${q}:`} </p>
                  <div className="select_input_wrapper multiselect" id={v}>
                    {o?.map((option, optionIndex) => {
                      return (
                        <div>
                          <Checkbox
                            disabled={isHandleLocked(handle)}
                            key={`option_${optionIndex}`}
                            name={option.label}
                            onChange={e => onMultiselectChanged(v, val, option.value, e.target.checked, repSectionIds, repIndices)}
                            checked={val && val.includes(option.value)}
                            label={option.label}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </CustomTooltip>
        </div>
      )
    }
  }

  // Render content
  const renderContent = (cntnt = content, vrbls = variables, repSectionIds = repeatableIds, repIndices = repetitionIndices, editable = isContentEditable, listIndex = -1) => {
    if (!cntnt) {
      return (
        <VariableText
          type={type}
          index={index}
          item={""}
          isContentEditable={repSectionIds && repSectionIds.length > 0 ? false : editable}
          onBlur={onContentChanged("", listIndex)} />
      )
    }
    const regex = /\u000b/g
    let source = cntnt.replace(regex, '')
    // let fieldRegex = /\{d\.([^}]*)\}/g
    const variableRegex = /\{d\.([^}]*)\}/g
    const formulaRegex = /\{f\(([^}]*)\}/g
    const questionRegex = /\{q\.([^}]*)\}/g
    const fieldRegex = /\{d\.([^}]*)\}|\{f\(([^}]*)\}|\{checkbox\.([^}]*)\}/g
    const checkboxRegex = /\{checkbox\.([^}]*)\}/
    let fields = source.match(fieldRegex)
    if (fields?.length > 0) {
      const selectVariables = vrbls ? [...vrbls].filter(v => v.type === 'select').map(v => (`{d.${v.variable}}`)) : [];
      fields.forEach(f => {
        if (selectVariables.includes(f)) {
          if (source.includes(`${f}.`)) {
            source = source.replace(`${f}.`, f);
          }
        }
      });
    }

    let items = []
    for (let i in fields) {
      if (!source) {
        continue
      }
      let components = source.split(fields[i])
      items.push(components[0])
      items.push(fields[i])
      components.splice(0, 1)
      source = components.join(fields[i])
    }
    if (source) {
      items.push(source)
    }
    return items.map((item, itemIndex) => {
      if (item === undefined || !item.match) {
        // TODO: fix occasional item being function ????
        // console.log("invalid item", { item: item ? item.toString() : item, itemIndex })
        return null
      }
      if (item.match(variableRegex) || item.match(questionRegex)) {
        let field = (fieldKeyFromHandle(item) || '').split(':')[0]
        // input field
        let fieldVariable = variableWithField(field, vrbls)
        if (!fieldVariable) {
          if (isRepeatableBlockHandle(field)) {
            return null
          } else {
            return item
          }
        }
        if (CONTACT_ID_VARIABLES.includes(fieldVariable.variable)) {
          return <VariableCustomerInput
            key={`key_${itemIndex}`}
            fieldVariable={fieldVariable}
            customerType={CONTACT_TYPE_FOR_ID_VARIABLE[fieldVariable.variable]}
            handle={item}
            val={value(fieldVariable.variable, repSectionIds, repIndices)}
            customersArrayForType={customersArrayForType}
            customerKeyForType={customerKeyForType}
            value={value}
            onCustomerChange={(val, customerType) => onCustomerChange(val, customerType, repSectionIds, repIndices)}
          />
        } else if (fieldVariable.type === 'string' || fieldVariable.type === 'number') {
          // return renderTextInput(fieldVariable, item, repId, repIndex, i)
          return <VariableTextInput
            key={`key_${itemIndex}`}
            fieldVariable={fieldVariable}
            handle={item}
            val={value(fieldVariable.variable, repSectionIds, repIndices)}
            repeatableIds={repSectionIds}
            repetitionIndices={repIndices}
            index={itemIndex}
            linesAdded={linesAdded}
            onInputChange={onInputChange}
            isHandleLocked={isHandleLocked}
            onNotariesModalOpen={onNotariesModalOpen}
            onAgencyModalOpen={onAgencyModalOpen}
          />
        } else if (fieldVariable.type === 'select') {
          return renderSelectInput(fieldVariable, item, repSectionIds, repIndices, itemIndex)
        } else if (fieldVariable.type === 'date') {
          // return renderDateInput(fieldVariable, item, repId, repIndex, i)
          return <VariableDateInput
            key={`key_${itemIndex}`}
            fieldVariable={fieldVariable}
            handle={item}
            val={value(fieldVariable.variable, repSectionIds, repIndices)}
            repeatableIds={repSectionIds}
            repetitionIndices={repIndices}
            index={itemIndex}
            linesAdded={linesAdded}
            onInputChange={onInputChange}
            isHandleLocked={isHandleLocked}
          />
        } else if (fieldVariable.type === 'multiselect') {
          return renderQuestion(fieldVariable, item, repSectionIds, repIndices, itemIndex)
        } else if (fieldVariable.type === 'textarea') {
          // return renderTextarea(fieldVariable, item, repId, repIndex, i)
          return <VariableTextarea
            key={`key_${itemIndex}`}
            fieldVariable={fieldVariable}
            handle={item}
            repeatableIds={repSectionIds}
            repetitionIndices={repIndices}
            val={value(fieldVariable.variable, repSectionIds, repIndices)}
            linesAdded={linesAdded}
            onTextAreaInputChange={(value) => onInputChange(fieldVariable.variable, value)}
            isHandleLocked={isHandleLocked}
          />
        } else if (fieldVariable.type === 'empty_lines') {
          // return renderEmptyLinesInput(fieldVariable, item, repId, repIndex, i)
          return <VariableEmptyLinesInput
            key={`key_${itemIndex}`}
            fieldVariable={fieldVariable}
            handle={item}
            repeatableIds={repSectionIds}
            repetitionIndices={repIndices}
            val={value(fieldVariable.variable, repSectionIds, repIndices)}
            emptyLinesLabelForValue={emptyLinesLabelForValue}
            onInputChange={onInputChange}
          />
        } else if (fieldVariable.type === 'area') {
          return <AreaInput key={`key_${itemIndex}`} fieldVariable={fieldVariable} handle={item} repSectionIds={repSectionIds} repIndices={repIndices} />
        } else {
          return item
        }
      } else if (item.match(formulaRegex)) {
        return renderFormulaInput(item, repSectionIds, repIndices, itemIndex)
      } else if (item.match(checkboxRegex)) {
        return renderCheckboxInput(checkboxIdFromHandle(item), repSectionIds, repIndices, itemIndex)
      } else {
        // return type.includes('heading') 
        //   ? <span key={i} className="doc-variable-el" data-id={`section-${index}`}>{item}</span> 
        //   : item ? (<span key={`key_${i}`}>{item}<span className="raw-text-space"></span></span>) : null
        return (
          <VariableText
            key={`key_${itemIndex}`}
            type={type}
            index={index}
            item={item}
            isContentEditable={repSectionIds && repSectionIds.length > 0 ? false : editable}
            // onInput={onContentChanged(item, listIndex)}
            onBlur={onContentChanged(item, listIndex)} />
        )
      }
    })
  }

  const AreaInput = ({fieldVariable, handle, repSectionIds = repeatableIds, repIndices = repetitionIndices}) => {
    const { variable, tooltip, idx } = fieldVariable
    let classes = "tooltip-wrapper doc-variable-el"

    const [val, setVal] = useState(value(variable, repSectionIds, repIndices))

    const handleBlur = () => {
        onInputChange(variable, printAreaValue(parseAreaValue(val)), repSectionIds, repIndices)
    }

    return (
      <div className={classes} data-id={`${idx}${repSectionIds && repSectionIds.length > 0 ? `-${repIndices.join('-')}` : ''}`}>
        <div style={{ padding: '2px 0' }}>
          <TextInput
            value={val}
            variable={variable}
            locked={isHandleLocked(handle)}
            type={'text'}
            onBlur={handleBlur}
            onInputChange={(v, val) => setVal(val)}
            tooltip={tooltip}
            linesAdded={linesAdded} />
        </div>
      </div>
    )
  }

  // Render text
  const renderText = () => {
    return (
      <div className="variables-v2__text">
        {renderContent()}
      </div>
    )
  }

  // Render bullet list
  const renderBulletList = () => {
    return (
      <ul className="variables-v2__text">
        {bulletListItems.map(renderBulletListItem)}
      </ul>
    )
  }

  // Render bullet list item
  const renderBulletListItem = (item, listIndex) => {
    return (
      <li className="bullet-list-item" style={{ marginLeft: `${1 * ((item.level + 1) || 1)}rem` }} key={`key_${listIndex}`}>
        {renderContent(item.content, item.variables, repeatableIds, repetitionIndices, isContentEditable, listIndex)}
      </li>
    )
  }

  // On table row count change
  const onTableRowCountChange = (newRowCount) => {
    if (newRowCount < 1) {
      return
    }
    let rowCount = 0
    if (!values[repeatableRowId]) {
      rowCount = 1
    } else {
      rowCount = values[repeatableRowId].length
    }
    if (newRowCount === rowCount) {
      return
    }
    let vals = values[repeatableRowId]
    if (!vals) {
      vals = [{}]
    }
    if (vals.length > newRowCount) {
      vals.splice(newRowCount, vals.length - newRowCount)
    } else {
      while (vals.length < newRowCount) {
        vals.push({})
      }
    }
    onValuesChange(repeatableRowId, vals)
  }

  // Render table
  const renderTable = () => {
    let rowCount = 0
    if (!repeatableRowId) {
      rowCount = 1
    } else if (!values[repeatableRowId]) {
      rowCount = 1
    } else {
      rowCount = values[repeatableRowId].length
    }
    let rowsObject = []
    for (let i = 0; i < rowCount; i++) {
      rowsObject.push(i)
    }
    return (
      <>
        {repeatableRowId && <RowCountInput disabled={rowsLocked} label="Nombre de lignes" onValueChange={onTableRowCountChange} value={!repeatableRowId ? 1 : !values[repeatableRowId] ? 1 : values[repeatableRowId].length} />}
        <table>
          {(head && head.length > 0) && <thead>
            <tr>
              {head.map((cell, ci) => <td className="variables-v2__text">{renderContent(cell.content, cell.variables, repeatableIds, repetitionIndices, false)}</td>)}
            </tr>
          </thead>}
          {(row && row.length > 0) && <tbody>
            {rowsObject.map((r, ri) => <tr key={`table_row_${ri}`}>
              {row.map((cell, ci) => <td key={`table_cell_${ri}_${ci}`} className="variables-v2__text">

                {head && <label className="field-label">{ head[ci].content }</label>}
                {renderContent(cell.content, cell.variables, [...(repeatableIds || []), repeatableRowId], [...(repetitionIndices || []), ri], false)}
              </td>)}
            </tr>)}
          </tbody>}
        </table>
      </>
    )
  }

  const renderStaticTable = () => {
    if (!rows) return null
    return (
      <div>
        <table>
          {rows.map((row, ri) => <tr key={`table_row_${ri}`}>
            {row.cells.map((cell, ci) =>
              <td key={`table_cell_${ri}_${ci}`} className="variables-v2__text">
                {cell?.sections?.map((section, si) =>
                  <SingleDocumentVariablesSectionContent
                    key={`table_cell_section_${ri}_${ci}_${si}`}
                    {...section}
                    index={si}
                    varIndex={ci}
                    repeatableIds={repeatableIds}
                    repetitionIndices={repetitionIndices}
                    values={values}
                    onValuesChange={onValuesChange}
                    documentCheckboxValues={documentCheckboxValues}
                    onCheckboxValueChange={onCheckboxValueChange}
                    locked={locked}
                    rowsLocked={rowsLocked}
                    isContentEditable={false}
                    onSectionContentChanged={onSectionContentChanged}
                    sectionIdx={section.idx}
                    documentAttachments={documentAttachments}
                    defaultAttachments={defaultAttachments}
                    onSetDocumentAttachments={onSetDocumentAttachments}
                    docId={docId}
                  />
                )}
              </td>)}
          </tr>)}
        </table>
      </div>
    )
  }

  const showAttachmentModal = (variable, repSectionIds = repeatableIds, repIndices = repetitionIndices) => {
    const val = value(variable.variable, repSectionIds, repIndices) || []
    setAttachmentSelectModal({
      isOpen: true,
      variable: variable,
      value: val,
      repeatableIds: repSectionIds,
      repetitionIndices: repIndices,
    })
  }

  const hideAttachmentModal = () => {
    setAttachmentSelectModal({
      isOpen: false,
      variable: null,
      repeatableIds: [],
      repetitionIndices: [],
      value: []
    })
  }

  const handleSaveAttachmentsVariable = async (attachments) => {
    let updatedAttachments = [...documentAttachments]
    for (let i in attachments) {
      if (!attachments[i].url && !attachments[i].deleted) {
        updatedAttachments.push(attachments[i])
      } else if (attachments[i].url && attachments[i].deleted) {
        updatedAttachments[i].deleted = true
      }
    }
    updatedAttachments = updatedAttachments.filter(att => !att.deleted)
    onSetDocumentAttachments([...updatedAttachments])
    setShowGlobalResponseLoader(true)
    await updateDocument({ attachments: updatedAttachments }, { id: docId, attachments: updatedAttachments })
    setShowGlobalResponseLoader(false)
    hideAttachmentModal()
  }

  const promptDeleteAttachment = (attachment) => {
    // TODO display confirmation modal
    setDeleteAttachmentAlert({ open: true, attachment })
  }

  const handleDeleteAttachment = async () => {
    onSetDocumentAttachments(documentAttachments.filter(att => att !== deleteAttachmentAlert.attachment))
    handleCloseDeleteAlert()
  }

  const handleCloseDeleteAlert = () => {
    setDeleteAttachmentAlert({ open: false, attachment: null })
  }

  const handlePreviewAttachment = (attachment) => {
    previewAttachment(attachment)
  }

  const renderAttachmentInput = (fieldVariable, handle, repSectionIds = repeatableIds, repIndices = repetitionIndices) => {

    const { variable, display_name, tooltip } = fieldVariable
    const attachmentsValue = (documentAttachments || []).filter(attachment => attachment.variable === variable)
    if (!isFeatureEnabled(FEATURE.REQUIRED_ATTACHMENTS)) {
      return null
    }
    return (
      <div className="variables-v2__text" data-id={`${sectionIdx}${repSectionIds && repSectionIds.length > 0 ? `-${repIndices.join('-')}` : ''}`}>
        <div className='variable-attachment'>
          <div className='attach-file-box'>
            <div className={`flex flex-wrap items-center gap-6`}>
              <span className='font-semibold'>{display_name || variable}</span>
              <CustomTooltip content={tooltip}>
                <button className="button button--with-icon btn-text"
                  onClick={() => showAttachmentModal(fieldVariable)}>
                  <Upload /> Ajouter une pièce jointe
                </button>
              </CustomTooltip>
            </div>
            {attachmentsValue.map((attachment, attachmentIndex) => {
              return (
                <div className='flex items-center gap-3 mt-3 attachment-list-item' key={attachmentIndex}>
                  <span className='flex items-center gap-3 !font-semibold'>
                    <Draft fontSize="small" /> {attachment.name}
                  </span>
                  <div className='flex items-center gap-2'>
                    <CustomTooltip content={"Aperçu"}>
                      <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={() => handlePreviewAttachment(attachment)}>
                        <Eye fontSize="small" />
                      </button>
                    </CustomTooltip>
                    <CustomTooltip content={"Supprimer"}>
                      <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={() => promptDeleteAttachment(attachment)}>
                        <DeleteOutline fontSize="small" />
                      </button>
                    </CustomTooltip>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }


  // Row count input
  const RowCountInput = ({ label, value, onValueChange, disabled = false }) => {

    const onButtonClick = (diff) => {
      let newValue = value + diff
      if (newValue < 1) {
        newValue = 1
      }
      onValueChange(newValue)
    }

    // const onInputChange = (e) => {
    //   let value = e.target.value
    //   onValueChange(value)
    // }

    return (
      <div className="row-count-input-container">
        <p className="row-count-input-label">
          {label}
        </p>
        <Button disabled={disabled} primary className="row-count-button" text="-" small onButtonClick={() => onButtonClick(-1)} />
        {/* <Input className="row-count-input" type="number" value={value} onChange={onInputChange} /> */}
        <p className={`row-count-input ${disabled && 'disabled'}`}>{value}</p>
        <Button disabled={disabled} primary className="row-count-button" text="+" small onButtonClick={() => onButtonClick(1)} />
      </div>
    )
  }

  // On input change
  const onInputChange = useCallback((variable, value, repSectionIds = repeatableIds, repIndices = repetitionIndices) => {
    const repeatableSectionIds = repSectionIds ? [...repSectionIds] : null
    const repetitionIndices = repIndices ? [...repIndices] : null
    if (repSectionIds && repSectionIds.length > 0) {
      const rootLevelRepeatableId = repeatableSectionIds.splice(0, 1)[0]
      const rootLevelValue = duplicatableValuesArray([rootLevelRepeatableId], [])

      let val = rootLevelValue

      while (repeatableSectionIds.length > 0) {
        val = val[repetitionIndices.splice(0, 1)[0]]
        if (!val) {
          throw new Error('Invalid repetition index')
        }
        if (!val[repeatableSectionIds[0]]) {
          val[repeatableSectionIds[0]] = [{}]
        }
        val = val[repeatableSectionIds.splice(0, 1)[0]]
      }
      if (Array.isArray(variable)) {
        for (let i in variable) {
          val[repetitionIndices[repetitionIndices.length - 1]][variable[i]] = value[i]
        }
      } else {
        val[repetitionIndices[repetitionIndices.length - 1]][variable] = value
      }

      onValuesChange(rootLevelRepeatableId, [...rootLevelValue])
    } else {
      onValuesChange(variable, value)
    }
  }, [onValuesChange, repeatableIds, repetitionIndices, duplicatableValuesArray])

  // Render formula input
  const renderFormulaInput = (handle, repSectionIds = repeatableIds, repIndices = repetitionIndices, index) => {
    // const val = value(variable, handle, repId, repIndex)
    const { value } = formulaResult(values, handle, repSectionIds, repIndices)
    let classes = "tooltip-wrapper doc-variable-el"

    return (
      <div className={classes} data-id={`${handle}${repSectionIds && repSectionIds.length > 0 ? `-${repIndices.join('-')}` : ''}`} key={`key_${index}`}>
        <div style={{ padding: '5px 0' }}>
          <TextInput value={value} variable={variable} locked />
        </div>
      </div>
    )
  }

  // Render checkbox input
  const renderCheckboxInput = (id, repSectionIds = repeatableIds, repIndices = repetitionIndices, index) => {
    return (
      <div className="checkbox-inline" key={`key_${index}`}>
        <Checkbox onChange={e => onCheckboxValueChange(id, e.target.checked, repSectionIds, repIndices)} checked={documentCheckboxValues[id] || false} />
      </div>
    )
  }

  // On manual reset click
  const manualResetClickHandler = () => {

  }

  // Render select input
  const renderSelectInput = (fieldVariable, handle, repSectionIds = repeatableIds, repIndices = repetitionIndices, index) => {
    return <SingleDocumentVariablesInner
      key={`key_${index}`}
      title={fieldVariable.tooltip}
      choices={fieldVariable.options}
      choicesName={fieldVariable.variable}
      documentValues={values}
      onValueChange={val => onInputChange(fieldVariable.variable, val, repSectionIds, repIndices)}
      tooltip={fieldVariable.tooltip}
      select={true}
      id={fieldVariable.variable}
      index={fieldVariable.idx}
      disabled={isHandleLocked(handle)}
      repeatableIds={repSectionIds}
      repetitionIndices={repIndices}
    />;
  }

  // On multi select change
  const onMultiselectChanged = (variable, prevState, value, checked, repSectionIds, repIndices) => {
    let nextState = [...(prevState || [])]
    if (checked && !nextState.includes(value)) {
      nextState.push(value)
    } else if (!checked && nextState.includes(value)) {
      nextState.splice(nextState.indexOf(value), 1)
    }
    onInputChange(variable, nextState, repSectionIds, repIndices)
  }

  // On customer change
  const onCustomerChange = (id, type, repSectionIds = repeatableIds, repIndices = repetitionIndices) => {
    let selectedCustomer
    let sourceArray = customersArrayForType(type)
    for (let i in sourceArray) {
      if (sourceArray[i][customerKeyForType(type, 'id')] === id) {
        selectedCustomer = sourceArray[i]
        break
      }
    }
    if (selectedCustomer) {
      let sourceKeys = dataKeyArrayForType(type)
      const customerValidKeys = []
      const customerValues = []
      for (let i in sourceKeys) {
        if (selectedCustomer[sourceKeys[i]]) {
          customerValidKeys.push(sourceKeys[i])
          customerValues.push(selectedCustomer[sourceKeys[i]])
        }
      }
      if (customerValidKeys.length > 0) {
        onInputChange(customerValidKeys, customerValues, repSectionIds, repIndices)
      }
    }
  }

  // Render section
  const renderSection = () => {
    if (!areSectionConditionsMet({ condition, conditions, repeatableIds, repetitionIndices }, values)) {
      return null
    }
    switch (type) {
      case 'heading1':
        return renderHeading1()
      case 'heading2':
        return renderHeading2()
      case 'heading3':
        return renderHeading3()
      case 'question':
        return renderQuestion()
      case 'text':
        return renderText()
      case 'bullet_list':
        return renderBulletList()
      case 'table':
        return renderTable()
      case 'static-table':
        return renderStaticTable()
      default:
        return null
    }
  }

  return isCoverPageSection() ? null : (
    <div>
      {renderSection()}
      {attachmentSelectModal.isOpen &&
        <AttachmentsVariableModal
          onClose={hideAttachmentModal}
          variable={attachmentSelectModal.variable}
          attachments={documentAttachments}
          defaultAttachments={defaultAttachments}
          onSave={handleSaveAttachmentsVariable}
          value={attachmentSelectModal.value || []}
        />
      }
      {deleteAttachmentAlert.open && <Alert
        onClose={handleCloseDeleteAlert}
        text={`Êtes-vous sûr de vouloir supprimer la pièce jointe - ${deleteAttachmentAlert.attachment.name} ?`}
        onSubmit={handleDeleteAttachment}
      />}
    </div>
  )
}

export default SingleDocumentVariablesSectionContent