import { useState, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Modal, Input, CustomTooltip, Checkbox } from '../ui_new'
import { UserPlus, Delete, CheckCircle, Close } from '../../assets/icons'
import { urlSuffixForEnvironment } from '../../utils'
import {
    UserContext,
    NotificationContext,
    LoaderContext,
    SingleDocumentContext,
} from '../../context'
import { useSingleDocumentActions } from '../../hooks'
import { validateEmail } from '../../helpers/validate'
import config from '../../config.json'
import { add_document_event } from '../../services/firestore'
import { Link } from '@material-ui/icons'
import { AddCircle, PersonAdd } from '@mui/icons-material'
import { useEffect } from 'react';

const SingleDocumentShare = ({
    onClose,
    singleDoc,
    documentName,
    documentId,
    attachments,
    onAttachmentShareUpdate,
    onAttachmentsShareSave,
    template,
    documentValues,
    useCustomCover,
}) => {

    const { filteredDefaultAttachments, filteredAttachmentVariables } = useContext(SingleDocumentContext)
    const { shareDocument } = useSingleDocumentActions()
    const { partner } = useContext(UserContext)
    const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } =
        useContext(LoaderContext)
    const { setNotification } = useContext(NotificationContext)
    const [view, setView] = useState('email')
    const [sentEmailId] = useState(uuidv4())
    const [email, setEmail] = useState('')
    const [recipients, setRecipients] = useState([
        {
            value: '',
            id: `recipient-${uuidv4()}`,
        },
    ])
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState(
        `
      <p>Bonjour,</p>

      <p>Vous trouverez ci-dessous le lien pour télécharger le document "${documentName}" pour relecture.</p>
      
      <p class="link">https://immodocs-cloud.web.app/document_v2${urlSuffixForEnvironment(
            config.environment
        )}/${documentId}/${sentEmailId}</p>
      
      <p>Nous restons à votre écoute pour toutes corrections et/ou modifications éventuelles.</p>
      
      <p>Cordialement,</p>
    `
    )

    const [defaultAttachments, setDefaultAttachments] = useState([])

    useEffect(() => {
        setDefaultAttachments([...filteredDefaultAttachments])
    }, [filteredDefaultAttachments])

    const handleToggleDefaultAttachment = (index) => {
        let newDefaultAttachments = [...defaultAttachments]
        newDefaultAttachments[index].share = !newDefaultAttachments[index].share
        setDefaultAttachments(newDefaultAttachments)
    }

    // On recipient change
    const handleRecipientChange = (e, id) => {
        setRecipients(
            recipients.map((r) =>
                r.id === id ? { ...r, value: e.target.value } : r
            )
        )
    }

    // On add new recipient
    const handleAddNewRecipient = (e) => {
        e.preventDefault()
        const id = `recipient-${uuidv4()}`
        let recipient = {
            value: '',
            id,
        }
        setRecipients([...recipients, recipient])
    }

    // On delete recipient
    const handleDeleteRecipient = (e, id) => {
        e.preventDefault()
        setRecipients(recipients.filter((r) => r.id !== id))
    }

    // On copy to clipboard
    const handleCopyToClipboard = () => {
        const value = `https://immodocs-cloud.web.app/document${urlSuffixForEnvironment(
            config.environment
        )}/${documentId}`

        navigator.clipboard.writeText(value)
        setNotification({ msg: 'Lien copié', type: 'success' })
    }

    // On submit
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (
            email.trim() === '' ||
            subject.trim() === '' ||
            message.trim() === ''
        ) {
            return setNotification({
                msg: 'Les champs ne peuvent pas être vides',
                type: 'danger',
            })
        }

        if (!validateEmail(email.trim())) {
            return setNotification({
                msg: 'Adresse email non valide',
                type: 'danger',
            })
        }

        let recipientsInvalidEmail = false
        for (let i = 0; i < recipients.length; i++) {
            if (!validateEmail(recipients[i].value)) {
                recipientsInvalidEmail = true
                break
            }
        }

        if (recipientsInvalidEmail) {
            return setNotification({
                msg: 'Adresse email non valide',
                type: 'danger',
            })
        }

        try {
            setShowGlobalResponseLoader(true)
            setGlobalResponseLoaderText('Partage du document')
            let data = {
                message,
                email: email.trim(),
                subject: subject.trim(),
                recipients,
                sentEmailId,
                // addWatermark: useWatermark,
            }
            await shareDocument(
                data,
                {
                    ...singleDoc,
                    name: documentName,
                    values: documentValues,
                    custom_cover: useCustomCover,
                    id: documentId,
                },
                template,
                [...attachments, ...(defaultAttachments.filter(a => a.share))]
            )
            await add_document_event(singleDoc.id, {
                type: 'email_share',
                values: singleDoc.values,
                content_changes: singleDoc.content_changes,
            })
            onClose()
        } catch (err) {
            console.log(err)
        } finally {
            setShowGlobalResponseLoader(false)
            setGlobalResponseLoaderText('')
        }
    }
    const blocksFromHTML = convertFromHTML(message)

    const [editorState, setEditorState] = useState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        )
    ));

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    return (
        <Modal onClose={onClose} className="modal-v2--mid-large">
            <div className="document-share folder-modal-v2">
                <div className="folder-modal-v2__heading">
                    <h2>Partager</h2>
                    <div className="buttons-row">
                        <button
                            className="btn btn--medium btn--transparent"
                            onClick={onClose}
                        >
                            Annuler
                        </button>
                        <button
                            className="button height-40"
                            onClick={onAttachmentsShareSave}
                        >
                            Sauvegardez les préférences de partage
                        </button>
                        <button
                            className='btn-separator'
                        >
                        </button>
                        <button className="button action-btn bg-pink" onClick={handleSubmit}>
                            <span>
                                <CheckCircle />
                                Envoyer
                            </span>
                        </button>
                    </div>
                    <button className="ml-auto fold-btn-mobile" onClick={onClose}>
                        <Close />
                    </button>
                </div>

                <div className="document-share__body">
                    <div className="attachments-wrapper-v2__inner">
                        {[...(attachments || [])].map((att, i) => {
                            return (
                                <Checkbox
                                    key={i}
                                    onChange={(e) => {
                                        onAttachmentShareUpdate(
                                            i,
                                            e.target.checked
                                        )
                                    }}
                                    checked={att.share}
                                    label={`${att.name}`}
                                />
                            )
                        })}
                        {[...(filteredDefaultAttachments || [])].map((att, i) => {
                            return (
                                <Checkbox
                                    key={i}
                                    onChange={(e) => handleToggleDefaultAttachment(i)}
                                    checked={att.share}
                                    label={`${att.name}`}
                                />
                            )
                        })}
                    </div>
                    <div className='description-box'>
                        <h3>Envoi du document </h3>
                        <p className='mb-6'>{documentName}</p>
                    </div>
                    <form
                        onSubmit={handleSubmit}
                        className="share-form"
                    >
                        <div className="share-form__field-wrapper w-1/2 mb-6">
                            <Input
                                label="Votre email"
                                value={email}
                                onChange={(e) =>
                                    setEmail(e.target.value)
                                }
                            />
                        </div>
                        {recipients.map((recipient, i) => (
                            <div
                                className={`share-form__field-wrapper w-1/2 with-icon mb-6`}
                                key={recipient.id}
                            >
                                <Input
                                    value={recipient.value}
                                    onChange={(e) =>
                                        handleRecipientChange(
                                            e,
                                            recipient.id
                                        )
                                    }
                                    label="Email du destinataire"
                                />
                                {i === 0 ? (
                                    <CustomTooltip content="Ajouter un destinataire">
                                        <a
                                            href="/#"
                                            className="icon"
                                            onClick={
                                                handleAddNewRecipient
                                            }
                                        >
                                            <PersonAdd fontSize='small' />
                                        </a>
                                    </CustomTooltip>
                                ) : (
                                    <CustomTooltip content="Supprimer le destinataire">
                                        <a
                                            href="/#"
                                            className="icon"
                                            onClick={(e) =>
                                                handleDeleteRecipient(
                                                    e,
                                                    recipient.id
                                                )
                                            }
                                        >
                                            <Delete />
                                        </a>
                                    </CustomTooltip>
                                )}
                            </div>
                        ))}
                        <div className="share-form__field-wrapper w-full mb-6">
                            <Input
                                label="Objet de l’emaill"
                                value={subject}
                                onChange={(e) =>
                                    setSubject(e.target.value)
                                }
                            />
                        </div>
                        <div className="share-form__field-wrapper w-full ">
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={onEditorStateChange}

                                toolbar={{
                                    options: [
                                        "history",
                                        "inline",
                                        "list",
                                        "textAlign",
                                        "remove"
                                    ],
                                    history: {
                                        undo: { icon: '/images/undo.png', className: "editor-icon" },
                                        redo: { icon: '/images/redo.png', className: "editor-icon" }
                                    },
                                    inline: {
                                        inDropdown: false,
                                        options: [
                                            "bold",
                                            "italic",
                                        ],
                                        bold: { icon: "/images/editor-icons/bold.svg", className: "editor-icon" },
                                        italic: { icon: "/images/editor-icons/italic.svg", className: "editor-icon" },
                                    },
                                    textAlign: {
                                        inDropdown: false,
                                        options: ["left", "center", "right", "justify"],
                                        left: { icon: "/images/editor-icons/align-left.svg", className: "editor-icon" },
                                        center: { icon: "/images/editor-icons/align-center.svg", className: "editor-icon" },
                                        right: { icon: "/images/editor-icons/align-right.svg", className: "editor-icon" },
                                        justify: { icon: "/images/editor-icons/align-justify.svg", className: "editor-icon" },
                                    },
                                    blockType: {
                                        inDropdown: true,
                                        options: [
                                            "Normal",
                                            "H1",
                                            "H2",
                                            "H3",
                                            "H4",
                                            "H5",
                                            "H6",
                                            "Blockquote",
                                            "Code"
                                        ]
                                    },
                                    list: {
                                        inDropdown: false,
                                        options: ["unordered", "ordered", "indent", "outdent"],
                                        unordered: { icon: "/images/editor-icons/bullet-list.svg", className: "editor-icon" },
                                        ordered: { icon: "/images/editor-icons/ordered-list.svg", className: "editor-icon" },
                                        indent: { icon: "/images/editor-icons/indent.svg", className: "editor-icon" },
                                        outdent: { icon: "/images/editor-icons/outdent.svg", className: "editor-icon" },
                                    },
                                    remove: { icon: "/images/editor-icons/clear-format.svg", className: "editor-icon" }
                                }}
                            />
                        </div>

                    </form>
                    <button
                        className="button height-40 button--outline-primary mt-6"
                        onClick={handleCopyToClipboard}
                    >
                        <Link />
                        Copier le lien de partage
                    </button>
                    <div className="buttons-row-mobile">
                        <button
                            className="buttton height-40"
                            onClick={onAttachmentsShareSave}
                        >
                            Sauvegardez les préférences de partage
                        </button>

                        <button className="button action-btn" onClick={handleSubmit}>
                            <span>
                                <CheckCircle />
                                Envoyer
                            </span>
                        </button>
                    </div>
                    {/* {view === 'link' && (
                        <div className="qr-code-section-v2">
                            <div className="qr-code-section-v2__inner">
                                <div className="qr-code-section-v2__left">
                                    <p>
                                        {`https://immodocs-cloud.web.app/document${urlSuffixForEnvironment(
                                            config.environment
                                        )}/${documentId}`}
                                    </p>

                                </div>
                                <div className="qr-code-section-v2__right">
                                    <p>
                                        Scanner ce QRCode depuis votre
                                        mobile ou tablette pour acceder au
                                        lien.
                                    </p>
                                    <QRCode
                                        value={`https://immodocs-cloud.web.app/document${urlSuffixForEnvironment(
                                            config.environment
                                        )}/${documentId}`}
                                    />
                                </div>
                            </div>
                        </div>
                    )} */}
                </div>
            </div>
        </Modal>
    )
}

export default SingleDocumentShare
