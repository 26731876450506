import { useState, useEffect, useRef } from "react"
import DatePicker from "react-datepicker"

import { CustomTooltip } from './'
import { CalendarAlt } from "../../assets/icons"
import { Cancel } from '@mui/icons-material';
import { ClickAwayListener } from '@material-ui/core'


const DateInput = ({ selectsRange = false, placeholder, label, onChange, selected, peekNextMonth = false, dateFormat = 'dd/MM/yyyy', showMonthDropdown = false, showYearDropdown = false, dropdownMode = '', disabled = false, tooltip, isClearable, onClearableClick, onChangeRaw, minDate = '', maxDate = '', className, expandable }) => {
  const [startDate, setStartDate] = useState('')
  const [dateRange, setDateRange] = useState([null, null]);
  const [expand, setExpand] = useState(false);
  const [rangeStartDate, rangeEndDate] = dateRange;
  const calendarRef = useRef();
  const [showTooltip, setShowTooltip] = useState(true); // Track tooltip visibility

  // Set start date if selected is set
  useEffect(() => {
    if (selected !== undefined || selected !== null) {
      setStartDate(selected)
    }
  }, [selected])

  // On date change
  const handleDateChange = (date) => {
    setStartDate(date)
    onChange(date)
    setShowTooltip(false); // Hide tooltip when date is selected
    setTimeout(() => {
      setShowTooltip(true);
    }, 500);
  }

  // On date change
  const handleDateRangeChange = (date) => {
    setDateRange(date);
    if (!date[0] || !date[1]) {
      return;
    }
    onChange(date)
    setShowTooltip(false); // Hide tooltip when date is selected
    setTimeout(() => {
      setShowTooltip(true);
    }, 500);
  }

  // On clear date
  const handleClear = () => {
    setStartDate('');
    setDateRange([null, null]);
    if (onClearableClick) onClearableClick();
    setExpand(false);
  }

  const calendarToggle = () => {
    if (expand) {
      unexpandCalendar();
    } else {
      setExpand(true);
      calendarRef.current.setOpen(true);
    }
  }

  const unexpandCalendar = () => {
    if (!rangeStartDate || !rangeEndDate) {
      setExpand(false);
    }
  }


  return (
    <ClickAwayListener onClickAway={unexpandCalendar}>
      <div className={`date-input ${className ? className : ''} ${disabled ? 'disabled' : ''} ${expandable ? 'expandable' : ''} ${expand ? 'expanded' : 'shrinked'} ${isClearable ? 'is-clearable' : ''}`}
      >
        {label && <div className="date-input__label">{label}</div>}
        {tooltip ? (
          <CustomTooltip content={tooltip} hideTooltip={!showTooltip}>
            <div className={!isClearable ? "date-input__body" : "date-input__body clearable"}>
              <CalendarAlt />
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                portalId="modal-root"
                locale="fr"
                dateFormat={dateFormat}
                peekNextMonth={peekNextMonth}
                showMonthDropdown={showMonthDropdown}
                showYearDropdown={showYearDropdown}
                dropdownMode={dropdownMode}
                disabled={disabled}
                onChangeRaw={onChangeRaw}
                minDate={minDate}
                maxDate={maxDate}
                placeholderText={placeholder}
              />
              {isClearable && <button className="clear-date-btn" onClick={handleClear}><Cancel /></button>}
            </div>
          </CustomTooltip>
        ) : (
          selectsRange ?
            <div className={!isClearable ? "date-input__body" : "date-input__body clearable"}>
              <div className="calendar-icon" onClick={calendarToggle}>
                <CalendarAlt />
              </div>
              <DatePicker
                selectsRange={true}
                onChange={handleDateRangeChange}
                portalId="modal-root"
                locale="fr"
                onChangeRaw={onChangeRaw}
                dateFormat={dateFormat}
                placeholderText={placeholder}
                startDate={rangeStartDate}
                endDate={rangeEndDate}
                value={rangeStartDate && rangeEndDate ? `${rangeStartDate.toLocaleDateString('fr-FR', { month: '2-digit', day: '2-digit', year: 'numeric' })} - ${rangeEndDate.toLocaleDateString('fr-FR', { month: '2-digit', day: '2-digit', year: 'numeric' })}` : ''}
                shouldCloseOnSelect={rangeStartDate && !rangeEndDate}
                ref={(c) => calendarRef.current = c}
              />
              {isClearable && <button className="clear-date-btn" type="button" onClick={handleClear}><Cancel /></button>}
            </div>
            :
            <div className={!isClearable ? "date-input__body" : "date-input__body clearable"}>
              <CalendarAlt />
              <DatePicker
                selected={selected || startDate}
                onChange={handleDateChange}
                portalId="modal-root"
                locale="fr"
                dateFormat={dateFormat}
                peekNextMonth={peekNextMonth}
                showMonthDropdown={showMonthDropdown}
                showYearDropdown={showYearDropdown}
                dropdownMode={dropdownMode}
                disabled={disabled}
                onChangeRaw={onChangeRaw}
                minDate={minDate}
                maxDate={maxDate}
                placeholderText={placeholder}
              />
              {isClearable && <button className="clear-date-btn" type="button" onClick={handleClear}><Cancel /></button>}
            </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default DateInput